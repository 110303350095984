import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material"
import Tooltip from '@mui/material/Tooltip';
import { IDocListItem } from "../../models/IDocListResponse"

const SelectToPrintModal =
    (props: {
        selectDocsModalOpen: boolean,
        openRefreshConfirm: any,
        handleClose: any
        documentList: Array<IDocListItem>
        selectedDocumentList: Array<IDocListItem>
        setSelectedDocumentList: any
    }) => {

        const selectDocToggle = (doc: IDocListItem) => { 
            const cIdx = props.selectedDocumentList.indexOf(doc);
            const newDocs = [...props.selectedDocumentList];
            if (cIdx === -1)
                newDocs.push(doc);
            else
                newDocs.splice(cIdx, 1);
            props.setSelectedDocumentList(newDocs)
        }

        const handleToggleSelection = () => {
            const newDocs = props.documentList.filter((doc) => !props.selectedDocumentList.includes(doc));
                props.setSelectedDocumentList(newDocs);            
        }

        const handleSelectAll = () => {
            const newDocs = props.selectedDocumentList.length === props.documentList.length ? [] : [...props.documentList];
            props.setSelectedDocumentList(newDocs);
        }

        return (
            <Dialog open={props.selectDocsModalOpen} onClose={props.handleClose}>
                <DialogTitle>
                    Select Documents ({props.selectedDocumentList.length}/{props.documentList.length})
                </DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>                            
                        <ListItemText 
                                primary="Document Name" 
                                primaryTypographyProps={{ fontWeight: 'bold' }}
                                sx={{ textAlign: 'center', width: '100%' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Tooltip title="Select All" placement="top">
                                    <Checkbox                                    
                                        edge="start"
                                        checked={props.selectedDocumentList.length === props.documentList.length}
                                        tabIndex={-1}
                                        onClick={handleSelectAll}
                                    />
                                </Tooltip>                                
                            </ListItemIcon>
                        </ListItem>
                        {props.documentList.map((doc) => {
                            return (
                                <ListItem key={doc.documentIndex} disablePadding>
                                    <ListItemButton role={undefined} onClick={() => { selectDocToggle(doc) }} dense>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={props.selectedDocumentList.includes(doc)}
                                                tabIndex={-1}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={`Label-Doc-${doc.documentIndex}`} primary={doc.name}></ListItemText>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button variant="contained" size="small" color="success" onClick={handleToggleSelection}>
                            Toggle Selection
                        </Button>
                    </Box>
                    <Button variant="contained" size="small" onClick={() => { props.handleClose(false) }}>Ok</Button>
                </DialogActions>
            </Dialog>
        )

    }

export default SelectToPrintModal;