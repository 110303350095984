import { Dispatch } from "redux";
import { Action, ActionType } from "../../actions";
import PushbackService from "../../../services/pushbackService";
import { IClientLogEntries } from "../../../models/configuration/pushback/IPushbackReport";

const getPushbackClientLogs = (configId: string, token: string | null, LoanNumber: string): 
(dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => { 
    return async (dispatch: Dispatch<Action>) => {

        dispatch({ type: ActionType.GET_PUSHBACK_CLIENT_LOGS });

        try {

            const payload = await new PushbackService().getPushbackLogs<IClientLogEntries>(token, configId, LoanNumber);

            dispatch({
                type: ActionType.GET_PUSHBACK_CLIENT_LOGS_SUCCESS,
                payload: payload,
            });

        } catch (error: any) {

            console.error("Get Pushback Client Logs");
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to get pushback client Logs data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_PUSHBACK_CLIENT_LOGS_FAILURE,
                    payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getPushbackClientLogs;