import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IConformXErrors, IErrorDetail } from "../../models/IDocGenStatusModel";

const ErrorModal = (props: {
    title: string,
    errors: IConformXErrors | null,
    modalOpen: boolean,
    handleModalClose: any
}) => {
    return (
        <Dialog open={props.modalOpen} onClose={props.handleModalClose} fullWidth={true} maxWidth="lg">
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                {props.errors?.general &&
                    <DocErrorDetail error={props.errors.general} errorTitle={"General"} />
                }
                {props.errors?.authentication &&
                    <DocErrorDetail error={props.errors.authentication} errorTitle={"Authentication"} />
                }
                {props.errors?.field &&
                    <DocErrorDetail error={props.errors.field} errorTitle={"Fields"} />
                }
                {props.errors?.prepaymentPenaltyProgram &&
                    <DocErrorDetail error={props.errors.prepaymentPenaltyProgram} errorTitle={"PrePayment Penalty Program"} />
                }
                {props.errors?.lateChargeProgram &&
                    <DocErrorDetail error={props.errors.lateChargeProgram} errorTitle={"Late Charge Program"} />
                }
                {props.errors?.dataIntegrity &&
                    <DocErrorDetail error={props.errors.dataIntegrity} errorTitle={"Data Integrity"} />
                }
                {props.errors?.loanProgram &&
                    <DocErrorDetail error={props.errors.loanProgram} errorTitle={"Loan Program"} />
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" size="small" onClick={() => { props.handleModalClose(false) }}>Ok</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorModal;


export const DocErrorDetail = (props: {
    error: IErrorDetail,
    errorTitle: string
}) => {
 
    return (
        <Accordion >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id={`${props.errorTitle}-Info`}  >
                <Typography component="span">{props.errorTitle}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.error.message &&
                    <Typography>{props.error.message}</Typography>
                }
                {props.error.violations &&
                    <List>
                        {props.error.violations.map((e, i) =>
                            <ListItem key={`error-${i}`} disablePadding>
                                <Typography>
                                    {e.violation}
                                </Typography>
                            </ListItem>
                        )}
                    </List>
                }
                {props.error.fields &&
                    <List>
                        {props.error.fields.map((e, i) =>
                            <ListItem key={`field-error-${i}`} disablePadding>
                                <Typography>{e.name} - {e.description}</Typography>
                            </ListItem>
                        )}
                    </List>
                }
            </AccordionDetails>
        </Accordion>
    )
} 
