import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Box } from '@mui/material';

interface LoansListDateFilterProps {
    DefaultDate: string;
    OutputDate: (date: string) => void;
}

const LoansListDateFilter: React.FC<LoansListDateFilterProps> =
    ({ DefaultDate, OutputDate }) => {

        const [value, setValue] = useState<Dayjs | null>(dayjs(DefaultDate));

        useEffect(() => {
            handleClick(dayjs(value).format('MM-DD-YYYY'));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [value]);

        const handleClick = (newStartDate: string) => {
            OutputDate(newStartDate);
        }

        return (
            <Box style={{ marginLeft: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker', 'DatePicker']}>
                        <DatePicker
                            label="(MM/dd/yyyy)"
                            defaultValue={value}
                            onChange={(newValue) => setValue(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
        )
    }

export default LoansListDateFilter;