import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IPluginConfig } from '../../models/configuration/importExport/IPluginConfig';
import PluginService from '../../services/pluginService';
import { RootState } from '../store'; // Adjust the path as necessary
import EncompassService from '../../services/encompassService';

interface ImportPluginConfigState {
  config: IPluginConfig | null;
  loading: boolean;
  error: string | null;
}

const initialState: ImportPluginConfigState = {
  config: null,
  loading: false,
  error: null,
};


export const saveImportPluginConfig = createAsyncThunk(
  'importPluginConfig/saveImportPluginConfig',
  async ({ configurationId, config }: { configurationId: string; config: IPluginConfig }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const pluginService = new PluginService();
    const requestBody = {
        "ConfigurationId": configurationId,
        "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
        "Configuration": {
            ...config
        }
    }
    return await pluginService.saveConfig(token, "plugin", configurationId, requestBody);
  }
);

const importPluginConfigSlice = createSlice({
  name: 'importPluginConfig',
  initialState,
  reducers: {
    setImportPluginConfig(state, action: PayloadAction<IPluginConfig>) {
      state.config = action.payload;
    },
    clearImportPluginConfig(state) {
      state.config = null;
    },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(saveImportPluginConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveImportPluginConfig.fulfilled, (state, action: PayloadAction<{ ConfigurationId: string; EncompassInstanceId: string; Configuration: IPluginConfig }>) => {
        state.loading = false;
        state.config = action.payload.Configuration; // Fix: Access the Configuration property
      })
      .addCase(saveImportPluginConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save import plugin config';
      });
  },
});

export const { setImportPluginConfig, clearImportPluginConfig } = importPluginConfigSlice.actions;
export default importPluginConfigSlice.reducer;
