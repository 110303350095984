export const ALL_USERS_ROLE = "-All Users-";
export const REQUEST_TYPE_LABEL = "Request Type";

export const REQUEST_TYPES = {
  BOTH: "Both",
  LIGHTS_OUT: "LightsOut",
  LIGHTS_ON: "LightsOn",
  DOCUMENT_REQUEST: "DocumentRequest",
  DOCUMENT_REQUEST_IMPORT: "DocumentRequestImport"
};

export const EPC_REQUEST_TYPES = {
  DISCLOSURES: "Disclosures",
  CLOSING: "Closing_Package",
  SELECT_TO_PRINT: "SelectToPrint"
}

export const DELIVERY_PREFIX = {
  EDISCLOSE: "edisclose",
  ESIGN: "esign",
  ECLOSE: "eclose",
  RON: "ron",
  ENOTE: "enote",
  IPEN: "ipen"
}

export const SAVE_LOAN_OPTIONS = [
  { value: "Always", label: "Always" },
  { value: "WhenRecalculationsAreNotComplete", label: "WhenRecalculationsAreNotComplete" },
  { value: "Never", label: "Never" }
];

export const REQUEST_TYPE_OPTIONS = [
  { value: "LightsOut", label: "LightsOut" },
  { value: "LightsOn", label: "LightsOn" },
  { value: "Both", label: "Both" }
];

export const DEFAULT_REQUEST_OPTIONS = [
  { value: "LightsOut", label: "LightsOut" },
  { value: "LightsOn", label: "LightsOn" }
];

export const PREVIEW_ATTACHMENTS_OPTIONS = [
  { value: "true", label: "True" },
  { value: "false", label: "False" }
];

export const PANEL_IDS = {

  ATTACHMENTS:"pnlAttachments",
  SELECT_TO_PRINT:"pnlSelectToPrint",
  REQUEST_TYPE:"pnlRequestType",
  LOGS_TAB: "tbLogs",
  REQUEST_RESPONSE_TAB: "tbReqResp",
  CONFIGURATION: "lnkConfigProxyControl"
}

export const EPC_ACTIONS = {
  GET_RESOURCES: "getAvailableResources",
  GET_ROLES: "getRoles"
}

export const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

export const API_REQUEST_STATUS = {
  PENDING: 1,
  COMPLETE: 2,
  ERROR: 3
}

export const PRESIGN_API_OBJECTS = {
  METADATA: "metadata.zip",
  DOCUMENTS: "generated_documents.zip"
}