import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import originSlice from "./originSlice";
import { 
    configServersReducer, 
    configPlugInReducer, 
    configDocumentBundlesReducer, 
    configEventBundlesReducer,
    configDocumentMappingReducer,
    configPushbackReducer,
    configXPathFieldMapReducer,
    requestPackageTypesReducer,
    pushbackLoansReducer,
    pushbackOrderDetailsReducer,
    pushbackDetailReducer, 
    pushbackReportReducer,
    pushbackClientLogsReducer
} from "./configReducers";
import importPluginConfigReducer from './slices/importPluginConfigSlice';
import integrationSlice from './slices/importIntegrationSlice';
import importPushbackSlice from './slices/importPushbackSlice';
import xpathFieldMapSlice from './slices/importXpathFieldMapSlice';
import importBundlingSlice from './slices/importBundlingSlice';
import importEventBundlingSlice  from './slices/importEventBundlingSlice';
import importDocumentMappingSlice from "./slices/importDocumentMappingSlice";

const reducer = combineReducers({
    appSlice,
    originSlice,
    configServers: configServersReducer,
    configPlugInData: configPlugInReducer,
    configDocumentBundles: configDocumentBundlesReducer,
    configEventBundles: configEventBundlesReducer,
    configDocumentMapping: configDocumentMappingReducer,
    configPushbackDefaults: configPushbackReducer,
    configXPathFieldMap: configXPathFieldMapReducer,
    requestPackageTypes: requestPackageTypesReducer,
    importPluginConfig: importPluginConfigReducer,
    importIntegration: integrationSlice,
    importPushback: importPushbackSlice,
    importXpathFieldMap: xpathFieldMapSlice,
    importDocumentMapping: importDocumentMappingSlice,
    importBundling: importBundlingSlice,
    importEventBundling: importEventBundlingSlice,
    pushbackLoans: pushbackLoansReducer,
    pushbackOrderDetails: pushbackOrderDetailsReducer,
    pushbackDetail: pushbackDetailReducer,
    pushbackReport: pushbackReportReducer,
    pushbackClientLogs: pushbackClientLogsReducer
})

export const store = configureStore({
    reducer
})

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = AppStore['dispatch'];