import React, { useEffect, useRef } from 'react';
import { Card, CardContent, List, ListItemButton, ListItemText } from '@mui/material';
import { IPackage } from '../../../models/configuration/plugin/IConfiguration';

interface PackagesListProps {
    packages: IPackage[] | null,
    selectedPackageIdx: number | null,
    selectPackage: (index: number, id: string) => void,
}

const PackagesList: React.FC<PackagesListProps> =
    ({ packages, selectedPackageIdx, selectPackage }) => {

        const prevCount = useRef<number>(packages ? packages.length : 0);

        // Handling the case when the packages are loaded and the selectedPackageIdx is null
        useEffect(() => {
            if (packages && packages.length > 0) {
                if (selectedPackageIdx === null) { // Handles first load selecting first item
                    selectPackage(0, packages[0].Id as string); 
                } else if (packages.length !== prevCount.current ) { 
                    selectPackage(0, packages[0].Id as string);
                    prevCount.current = packages.length;
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [packages, selectedPackageIdx]);

        return (
            <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2 }}>
                <CardContent>
                    {packages && packages.length === 0 && <div>No packages found</div>}
                    {packages && packages.length > 0 && (
                        <List>
                            {packages?.sort((a, b) => a.Id.localeCompare(b.Id)).map((p, index) => (
                                <ListItemButton
                                    key={index}
                                    sx={{ color: p.Enabled ? 'blue' : 'grey' }}
                                    selected={selectedPackageIdx === index}
                                    onClick={() => selectPackage(index, p.Id as string)}
                                >
                                    <ListItemText primary={p.Id} />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </CardContent>
            </Card>
        );
    }

export default PackagesList;