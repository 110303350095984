import { Dispatch } from "redux";
import { Action, ActionType } from "../../actions";
import PushbackService from "../../../services/pushbackService";
import { IPushbackOrderDetails } from "../../../models/configuration/pushback/IPushbackLoans";

const getPushbackOrderDetailsData = (configId: string, token: string | null, loanNumber: string): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_PUSHBACK_ORDER_DETAILS });

        try {

            const payload = await new PushbackService().getOrderDetails<IPushbackOrderDetails>(token, configId, loanNumber);

            dispatch({
                type: ActionType.GET_PUSHBACK_ORDER_DETAILS_SUCCESS,
                payload: payload,
            });

        } catch (error: any) {

            console.error("Get Pushback Order Details");
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to pushback Order details data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_PUSHBACK_ORDER_DETAILS_FAILURE,
                    payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getPushbackOrderDetailsData;