import { useEffect, useState } from "react";
import { SelectToPrintCardProps } from "../../models/GenericCardProps";
import { Card, CardHeader, CardContent, List, CardActions, Button, ListItem, ListItemText } from "@mui/material";
import RefreshDocsDialog from "./RefreshDocsDialog";
import { IDocListItem, IDocListResponse } from "../../models/IDocListResponse";
import SelectToPrintModal from "./SelectToPrintModal";
import ErrorModal from "./ErrorModal";
import { IConformXErrors } from "../../models/IDocGenStatusModel";

function SelectToPrintCard(props: SelectToPrintCardProps) {
    const [confirmUpdateOpen, setConfirmUpdateOpen] = useState<boolean>(false);
    const [selectDocsModalOpen, setSelectDocsModalOpen] = useState<boolean>(false);
    const [docErrors, setDocErrors] = useState<IConformXErrors | null>(null);
    const [docErrorOpen, setDocErrorOpen] = useState<boolean>(false);
    const [enableButton, setEnableButton] = useState<boolean>(false);

    const cardVisibility = () => {
        if (props.visible)
            return 'flex';
        return 'none';
    }

    //handle the updated document list; used in update dialog
    //should show errors when applicable
    const handleUpdatedDocuments = (response: IDocListResponse) => {
        if (response.result.conformXResponse?.errors) {
            setDocErrors(response.result.conformXResponse!.errors);
            setDocErrorOpen(true);
        }

        if (response.result.conformXResponse?.documentList?.documents && response.result.conformXResponse?.documentList?.documents?.length > 0) {
            props.setListMethod(response.result.conformXResponse.documentList.documents);
            setSelectDocsModalOpen(true);
            props.setSelectedListMethod([]);
        }
    }

    //close the errors dialog
    const handleErrorDialogClose = () => {
        setDocErrors(null);
        setDocErrorOpen(false);
    }

    //close the select docs dialog
    const handleCloseDocsModal = () => {
        setSelectDocsModalOpen(false)
    }

    //close the confirmation dialog
    const handleConfirmClose = async (ok: boolean) => {
        if (ok) {
            setSelectDocsModalOpen(true);
        }
        setConfirmUpdateOpen(false);
    }

    //clicking select to Print Button
    const handleSelectToPrintButton = () => {
        if (props.docList.length <= 1) {
            handleSelectDocument([]);
            setConfirmUpdateOpen(true);
        }
        else {
            setSelectDocsModalOpen(true);
        }
    }

    //update selected list
    const handleSelectDocument = (docs: Array<IDocListItem>) => {
        props.setSelectedListMethod(docs);
    }

    //clear button
    const handleClearSelectedButton = () => {
        props.setSelectedListMethod([]);
    }

    //setting button enabled
    useEffect(() => {
        if (props.packageId && props.enabled) {
            setEnableButton(true)
        } else
            setEnableButton(false);
    }, [props.packageId, props.enabled])

    return (
        <>
            <Card sx={{ p: 2, display: cardVisibility, flexDirection: 'column' }}>
                <CardHeader title={`${((props.title && props.title!== "")? props.title : "Select to Print")} ${props.selectedDocList.length}/${props.docList.length} `}>
                </CardHeader>
                <CardContent sx={{ overflow: 'auto', paddingTop: "0px", marginTop: "-15px" }}>
                    <List>
                        {props.selectedDocList.map((doc) => {
                            return (
                                <ListItem key={doc.documentIndex}
                                    disablePadding
                                >
                                    <ListItemText primary={doc.name} />
                                </ListItem>
                            )
                        })}

                    </List>
                </CardContent>
                <CardActions sx={{
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                }}>
                    <Button variant="contained" size="small" disabled={!enableButton} onClick={handleSelectToPrintButton}>Select to Print</Button>
                    <Button variant="contained" size="small" color="error" disabled={!props.enabled} onClick={handleClearSelectedButton}>Clear</Button>
                </CardActions>
            </Card>
            <RefreshDocsDialog
                confirmUpdateOpen={confirmUpdateOpen}
                handleConfirmClose={handleConfirmClose}
                packageType={props.packageId ?? ""}
                altPackageId={props.altPackageId ?? ""}
                parentPackageId={props.parentPackageId ?? ""}
                handleUpdatedDocs={handleUpdatedDocuments}
            />
            <SelectToPrintModal
                selectDocsModalOpen={selectDocsModalOpen}
                openRefreshConfirm={() => { setConfirmUpdateOpen(true); }}
                handleClose={handleCloseDocsModal}
                documentList={props.docList}
                selectedDocumentList={props.selectedDocList}
                setSelectedDocumentList={handleSelectDocument}
            />
            <ErrorModal
                title="Errors fetching document list"
                errors={docErrors}
                modalOpen={docErrorOpen}
                handleModalClose={handleErrorDialogClose}
            />
        </>
    )
}
export default SelectToPrintCard;