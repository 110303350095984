import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IXpathFieldMap } from "../../models/configuration/xpathFieldMap/IXpathFieldMap";

// This action creator gets the Xpath field data from an api call or state
const getConfigXpathFieldMapData = (
    configId: string,
    token: string | null
    ): ((dispatch: Dispatch<Action>, getState: () => any) => Promise<void>) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {
        dispatch({ type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING });
    
        try {
        const { data: xpathFieldMap } = getState().configXPathFieldMap;
    
        if (xpathFieldMap !== null) {
            dispatch({
            type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_SUCCESS,
            payload: xpathFieldMap,
            });
        } else {
            const payload =
            await new PluginService().getConfig<IXpathFieldMap>(
                token,
                "xpath-fieldmap",
                configId
            );
            dispatch({
            type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_SUCCESS,
            payload: payload,
            });
        }
        } catch (error: any) {
        console.error("Get Xpath field Data");
        if (error instanceof Error) {
            const payloadErrorMessage = `Failed to fetch Xpath field Data: (${error.message})`;
            dispatch({
            type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_FAILURE,
            payload: payloadErrorMessage,
            });
        }
        }
    };
    }

    export default getConfigXpathFieldMapData;