import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';
import { signedDocColumns } from "./configbundlingdata";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import { IDocumentBundle, IEventBundle } from "../../../models/configuration/bundles/IBundles";
import ConfigDocumentHandlingDialog from "./ConfigDocumentHandlingDialog";
import ConfirmationDialog from "../../../components/shared/ConfirmDialog";
import { AlertSeverity } from "../../../constants/AlertTypes";

interface OtherDocumentBundlingDialogProps {
    eventBundle: IEventBundle | null;
    open: boolean;
    closeFxtn: () => void;
    updateEventBundle: (eventBundle: IEventBundle) => void;
}

const OtherDocumentBundlingDialog: React.FC<OtherDocumentBundlingDialogProps> = ({ open, eventBundle, updateEventBundle, closeFxtn, }) => {

    const [eventBundleState, setEventBundleState] = useState<IEventBundle | null>(null);
    const [selectedBundle, setSelectedBundle] = useState<IDocumentBundle | null>(null);
    const [editingBundle, setEditingBundle] = useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    useEffect(() => {
        setEventBundleState(eventBundle);
    }, [eventBundle]);

    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            if (eventBundleState && eventBundleState !== eventBundle) { // ensure a difference before updating parent state
                updateEventBundle(eventBundleState);
            }
        } else {
            isMounted.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventBundleState]);

    // when selected bundle is changed, update the main event Bundle state
    useEffect(() => {
        if (selectedBundle && selectedBundle !== eventBundleState?.Configuration?.find(bundle => bundle.PackageId === selectedBundle.PackageId)) {
            setEventBundleState((prevState) => {
                const newBundles = prevState?.Configuration?.map((bundle) => {
                    if (bundle.PackageId === selectedBundle.PackageId) {
                        return selectedBundle;
                    }
                    return bundle;
                });
                return { ...prevState, Configuration: newBundles || null };
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBundle]);

    function editItem(e: any, item: any) {
        setSelectedBundle(item);
        setEditingBundle(true);
    }

    function deleteItem(e: any, item: any) {
        setSelectedBundle(item);
        setConfirmDialogOpen(true);
    }

    function deleteBundle(item: any) {
        if (eventBundleState?.Configuration) {
            const newBundles = eventBundleState.Configuration.filter((bundle) => bundle !== item);
            setEventBundleState({ ...eventBundleState, Configuration: newBundles });
            openAlert(`Bundle Configuration for ${item.PackageId} Deleted Successfully`, 'success');
            setSelectedBundle(null);
            setConfirmDialogOpen(false);
        }
    }

    function cancelDeleteBundle() {
        setConfirmDialogOpen(false);
        setSelectedBundle(null);
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <>

            <Dialog open={open} onClose={closeFxtn} fullWidth maxWidth="lg">
                <DialogTitle>
                    {eventBundleState?.EventName}
                    <IconButton
                        aria-label="close"
                        onClick={closeFxtn}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%" }}>
                        <Box
                            sx={{ display: "flex", flexDirection: "column", gap: 2, height: "100%" }}
                        >
                            <Card sx={{ mt: 1 }}>
                                <CardContent>

                                    {eventBundleState?.Configuration?.length === 0 ? (
                                        <Box sx={{ display: "flex", justifyContent: "center" }}>No Bundling Configurations</Box>
                                    ) : (
                                        <DTDataGrid
                                            data={eventBundleState?.Configuration || []}
                                            columns={signedDocColumns(editItem, deleteItem)}
                                            tableName="otherDocBundles"
                                            containerSx={{ maxHeight: "60vh" }}
                                        />
                                    )}

                                </CardContent>
                            </Card>
                            {selectedBundle && editingBundle}
                            {
                                <ConfigDocumentHandlingDialog
                                    open={editingBundle}
                                    documentBundle={selectedBundle!}
                                    updateBundle={setSelectedBundle}
                                    closeFxtn={() => setEditingBundle(false)}
                                />
                            }

                            <ConfirmationDialog
                                open={confirmDialogOpen}
                                title="Confirm Delete"
                                message={`Are you sure you want to delete the  '${selectedBundle?.BundlingConfigurationType}' Bundling Configuration for ${selectedBundle?.PackageId}?`}
                                onConfirm={() => deleteBundle(selectedBundle)}
                                onCancel={() => cancelDeleteBundle()}
                            />

                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFxtn} 
                    variant="text">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </>
    );
};

export default OtherDocumentBundlingDialog;