import { IPushbackLoans } from "../../../models/configuration/pushback/IPushbackLoans";
import { Action, ActionType } from "../../actions";

interface PushbackLoansState {
  loading: boolean;
  error: string | null;
  data: IPushbackLoans | null;
}

const initialState = { loading: false, error: null, data: null };

const pushbackLoansReducer = (
  state: PushbackLoansState = initialState,
  action: Action
): PushbackLoansState => {
  switch (action.type) {
    // GET DATA
    case ActionType.GET_PUSHBACK_LOANS:
      return { ...state, loading: true, error: null };
    case ActionType.GET_PUSHBACK_LOANS_FAILURE:
        return { ...state, loading: false, error: action.payload };
    case ActionType.GET_PUSHBACK_LOANS_SUCCESS:
        return { ...state, loading: false, error: null, data: action.payload };
    default:
        return state;
  }
};

export default pushbackLoansReducer;