import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import PluginService from '../../services/pluginService';
import { IDocumentMappingConfiguration } from '../../models/configuration/bundles/IDocumentMappings';

interface BundlingState {
  config: IDocumentMappingConfiguration | null;
  loading: boolean;
  error: string | null;
}

const initialState: BundlingState = {
  config: null,
  loading: false,
  error: null,
};

export const importDocumentMappingConfig = createAsyncThunk(
  'importDocumentMapping/importDocumentMappingConfig',
  async ({ configurationId, config }: { configurationId: string; config: IDocumentMappingConfiguration }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const pluginService = new PluginService();
    return await pluginService.saveConfig<IDocumentMappingConfiguration>(token, "document-map", configurationId, config);
  }
);

const importDocumentMappingSlice = createSlice({
  name: 'bundling',
  initialState,
  reducers: {
    clearBundlingConfig(state) {
      state.config = null;
    },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(importDocumentMappingConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(importDocumentMappingConfig.fulfilled, (state, action: PayloadAction<IDocumentMappingConfiguration>) => {
        state.loading = false;
        state.config = action.payload;
      })
      .addCase(importDocumentMappingConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save bundling config';
      });
  },
});

export const { clearBundlingConfig } = importDocumentMappingSlice.actions;
export default importDocumentMappingSlice.reducer;
