import { useEffect, useCallback } from "react";
import { useActions } from "../useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import { useTypedSelector } from "../useTypedSelector";

function usePushbackClientLogs(loanNumber: string) {

    const { getPushbackClientLogsData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.pushbackClientLogs);

    const isLoanNumberValid = loanNumber && loanNumber.trim() !== "";

    // Memoized fetch function
    const fetchData = useCallback(async () => {
        if (isLoanNumberValid) {
            const configId = EncompassService.getConfigId();
            if (configId) {
                await getPushbackClientLogsData(configId, accessToken, loanNumber); // Access token not required since proxy will generate if needed locally
            }
        }
    }, [getPushbackClientLogsData, accessToken, loanNumber, isLoanNumberValid]);

    useEffect(() => {
        if (isLoanNumberValid) {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanNumber]);

    // Return error if loanNumber is invalid
    if (!isLoanNumberValid) {
        return { data: null, loading: false };
    }

    return { data, error, loading };
}

export { usePushbackClientLogs };