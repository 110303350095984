import { DTDataGridAlignment, DTDataGridColumnType, IDataColumn } from "../../models/IDataColumn";
import {IPackage} from "../../models/configuration/plugin/IConfiguration";
import {Tooltip, Typography} from "@mui/material";

///TODO: Pending development due to expression data dependency
export const evaluationResultsColumns = (): Array<IDataColumn> => {
    return [
    {
        name: "Result",
        fieldId: "",
        datatype: DTDataGridColumnType.checkbox,
        alignment: DTDataGridAlignment.center,
        readonly: true
    },
    {
        name: "Control Type",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Id",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Group",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Message",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    }
    ];
};

export const validDeliveryMethodsColumns = (): Array<IDataColumn> => {
    return [
    {
        name: "Enabled",
        fieldId: "Enabled",
        datatype: DTDataGridColumnType.checkbox,
        alignment: DTDataGridAlignment.center,
        readonly: true
    },
    {
        name: "Group",
        fieldId: "Group",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        sortable: true
    },
    {
        name: "Package",
        fieldId: "Id",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        sortable: true
    },
    {
        name: "Valid Delivery Types",
        fieldId: "ValidDeliveryTypes",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        sortable: true,
        dataFunction: (pkg : IPackage) =>
             {
                return (
                     <Tooltip title={<span style={{ fontSize: "1rem" }}>{pkg.ValidDeliveryTypes.map(String).join(', ')}</span>} arrow>
                     <Typography>{pkg.ValidDeliveryTypes.map(String).join(', ')}</Typography> 
                    </Tooltip>
                )
             }
    }
    ];
};
