import React, { useEffect, useState } from 'react';
import { Card, CardContent, TextField, Box, Typography } from '@mui/material';
import { ICustomAttributes, IDeliveryType } from '../../../models/configuration/plugin/IConfiguration';
import { IPENVendor, IframeVendor, VerificationVendorType, FormFreeLenderID, attributeOrder, showIPENVendor, showVerificationVendorType, showFormFreeLenderID } from '../../../constants/CustomAttributeConstants';

const initialCustomAttributesState: ICustomAttributes = {
    IPENVendor: null,
    IframeVendor: null,
    VerificationVendorType: null,
    FormFreeLenderID: null,
};

interface CustomAttributesProps {
    customAttributes: ICustomAttributes | undefined;
    updateParent: (attributes: ICustomAttributes) => void;
    selectedDeliveryType: IDeliveryType | null;
}

const CustomAttributes: React.FC<CustomAttributesProps> = ({ customAttributes, updateParent, selectedDeliveryType }) => {
    const [customAttributesState, setCustomAttributesState] = useState<ICustomAttributes>(initialCustomAttributesState);

    // Set the initial state if customAttributes are present
    useEffect(() => {
        if (customAttributes) {
            setCustomAttributesState(customAttributes);
        }
    }, [customAttributes]);

    const handleInputChange = (key: keyof ICustomAttributes) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedState = { ...customAttributesState, [key]: event.target.value };
        setCustomAttributesState(updatedState);
        updateParent(updatedState);
    };

    function updateFriendly(key: string): React.ReactNode {
        switch (key) {
            case IframeVendor:
                return 'Iframe Vendor';
            case IPENVendor:
                return 'IPEN Vendor';
            case VerificationVendorType:
                return 'Verification of Information Vendor Type';
            case FormFreeLenderID:
                return 'Freeform Vendor ID';
        }
    }

    const shouldDisplayAttribute = (selectedDeliveryType: IDeliveryType | null, attribute: string) => {
        switch (attribute) {
            case IPENVendor:
                return showIPENVendor.includes(selectedDeliveryType?.Id || "");
            case  VerificationVendorType:
                return showVerificationVendorType.includes(selectedDeliveryType?.Id || "");
            case FormFreeLenderID:
                return showFormFreeLenderID.includes(selectedDeliveryType?.Id || "");
        }
        return true;
    }

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2, width: "100%" }}>
            <CardContent>
                {attributeOrder.map((key) => {
                    return shouldDisplayAttribute(selectedDeliveryType, key) ? (
                        <Box key={key} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                            <Typography sx={{ flex: 1, marginRight: ".2rem" }}>{updateFriendly(key)}:</Typography>
                            <TextField
                                sx={{ flex: 2 }}
                                value={customAttributesState[key as keyof ICustomAttributes] || ''}
                                onChange={handleInputChange(key as keyof ICustomAttributes)}
                            />
                        </Box>
                    ) : null;
                })}
            </CardContent>
        </Card>
    );
};

export default CustomAttributes;