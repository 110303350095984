import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IXpathFieldMapping } from '../../models/configuration/importExport/IXpathFieldMappingConfiguration';
import PluginService from '../../services/pluginService';
import { RootState } from '../store'; // Adjust the path as necessary
import { IXpathFieldMappingConfiguration } from '../../models/configuration/importExport/IXpathFieldMappingConfiguration';
import { IXpathFieldMapSave } from '../../models/configuration/xpathFieldMap/IXpathFieldMap';

interface XpathFieldMapState {
  xPathfieldMap: IXpathFieldMapping[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: XpathFieldMapState = {
  xPathfieldMap: [] as IXpathFieldMapping[],
  loading: false,
  error: null,
};

export const importXpathFieldMap = createAsyncThunk(
  'xpathFieldMap/importXpathFieldMap',
  async ({ configurationId, xPathfieldMap }: { configurationId: string; xPathfieldMap: IXpathFieldMappingConfiguration }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const pluginService = new PluginService();
    const requestBody = {
        "ConfigurationId": configurationId,
        "Configuration": {
            FieldMappings: xPathfieldMap.FieldMappings
        }
    }
    return await pluginService.saveConfig<IXpathFieldMapSave>(token, "xpath-fieldmap", configurationId, requestBody);
  }
);

const xpathFieldMapSlice = createSlice({
  name: 'xpathFieldMap',
  initialState,
  reducers: {
    setXpathFieldMap(state, action: PayloadAction<IXpathFieldMapping[]>) {
      state.xPathfieldMap = action.payload;
    },
    clearXpathFieldMap(state) {
      state.xPathfieldMap = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importXpathFieldMap.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(importXpathFieldMap.fulfilled, (state, action: PayloadAction<{ ConfigurationId: string | null; Configuration: { FieldMappings: IXpathFieldMapping[] } }>) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(importXpathFieldMap.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save xpath field map';
      });
  },
});

export const { setXpathFieldMap, clearXpathFieldMap } = xpathFieldMapSlice.actions;
export default xpathFieldMapSlice.reducer;
