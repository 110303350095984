import { Grid, Typography } from "@mui/material";
import GenericDialog from "./shared/GenericDialog";

interface AdminSetupModalProps {
    open: boolean;
    onClose: () => void;
    text: string;
    isAdmin: boolean;
}

const AdminSetupModal: React.FC<AdminSetupModalProps> = ({ open, onClose, text, isAdmin }) => {

    return (
        <>
            <GenericDialog
                open={open}
                onClose={onClose}
                title="Setup Needed"
            >
                <Grid item>
                    {!isAdmin ?
                        <Typography variant="h5">
                            Please contact your administrator.
                        </Typography> : <></>}
                    <Typography>
                        {text}
                    </Typography>
                </Grid>
            </GenericDialog>
        </>
    )
}

export default AdminSetupModal;