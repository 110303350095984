import { useEffect, useCallback } from "react";
import { useActions } from "../useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import { useTypedSelector } from "../useTypedSelector";

function usePushbackDetail(orderId: string) {

    const { getPushbackDetailData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.pushbackDetail);

    const isOrderNumberValid = orderId && orderId.trim() !== "";

    const fetchData = useCallback(() => {
        if (isOrderNumberValid) {
            const configId = EncompassService.getConfigId();
            if (configId) {
                getPushbackDetailData(configId, accessToken, orderId);
            }
        }
    }, [getPushbackDetailData, accessToken, orderId, isOrderNumberValid]);

    // Effect to fetch data
    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    if (!isOrderNumberValid) {
        return { data: null, error: "", loading: false };
    }

    // Return loading, error, and data for better flexibility
    return { data, loading, error };
}

export { usePushbackDetail };