import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface PackageGroupRenameProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (name: string) => void;
    existingName: string;
}

const PackageGroupRename: React.FC<PackageGroupRenameProps> = ({ open, onClose, onSubmit, existingName }) => {

    const [newName, setNewName] = useState<string>("");

    useEffect(() => {
        setNewName("");
    }, [existingName]);

    function submitChange(): void {
        onSubmit(newName);
        onClose();
    }

    const validate = (name: string): boolean => {
        return name.trim().length > 0;
    }
    
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Rename Package Group</DialogTitle>
            <DialogContent>
                <Box sx={{ color: (theme) => theme.palette.primary.main , fontWeight: "bold"   }} >Existing Name: {existingName}</Box>
                <Box>
                    <Typography fontSize={14}>Rename To:</Typography>

                    <TextField
                        fullWidth
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                     <Typography variant="h6"  sx={{ color: (theme) => theme.palette.warning.main }} 
                            component="h1" align="center" gutterBottom>    
                            Renaming to existing group will merge
                        </Typography>    
                </Box>
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={submitChange} disabled={!validate(newName)}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};
export default PackageGroupRename;