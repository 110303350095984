import { useState } from "react";

function useDialogData() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogSubMessage, setDialogSubMessage] = useState('');

    return {
        dialogOpen, setDialogOpen, 
        dialogTitle, setDialogTitle, 
        dialogMessage, setDialogMessage, 
        dialogSubMessage, setDialogSubMessage
    };
}

export { useDialogData };