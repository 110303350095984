import { Dispatch } from "redux";
import { Action, ActionType } from "../../actions";
import PushbackService from "../../../services/pushbackService";
import { IPushbackDetail } from "../../../models/configuration/pushback/IPushbackLoans";

const getPushbackDetail = (configId: string, token: string | null, OrderId: string): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>) => {

        dispatch({ type: ActionType.GET_PUSHBACK_DETAIL });

        try {

            const payload = await new PushbackService().getPushbackDetail<IPushbackDetail>(token, configId, OrderId);

            dispatch({
                type: ActionType.GET_PUSHBACK_DETAIL_SUCCESS,
                payload: payload,
            });

        } catch (error: any) {
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to get pushback detail data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_PUSHBACK_DETAIL_FAILURE,
                    payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getPushbackDetail;