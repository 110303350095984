import { IBundleConfiguration,IEventBundleConfiguration } from '../../models/configuration/bundles/IBundles';
import { IDocumentMappingConfiguration } from '../../models/configuration/bundles/IDocumentMappings';
import { IPluginConfiguration } from '../../models/configuration/plugin/IConfiguration';
import { IPushbackConfiguration } from '../../models/configuration/pushback/IConfiguration';
import { IPushbackDetail, IPushbackEntry, IPushbackLoans, IPushbackOrderDetails } from '../../models/configuration/pushback/IPushbackLoans';
import { IClientLogEntries, IPushbackReport } from '../../models/configuration/pushback/IPushbackReport';
import IIntegration from '../../models/configuration/servers/IIntegration';
import { IXpathFieldMap } from '../../models/configuration/xpathFieldMap/IXpathFieldMap';
import { IPackageTypes } from '../../models/request/IRequest';

export enum ActionType {
    // All Plug In Data
    GET_CONFIG_PLUGIN_DATA = 'get_plugin_data', GET_CONFIG_PLUGIN_DATA_SUCCESS = 'get_plugin_data_success', GET_CONFIG_PLUGIN_DATA_FAILURE = 'get_plugin_data_failure',
    SAVE_CONFIG_PLUGIN_DATA = 'save_plugin_data', SAVE_CONFIG_PLUGIN_DATA_SUCCESS = 'save_plugin_data_success', SAVE_CONFIG_PLUGIN_DATA_FAILURE = 'save_plugin_data_failure',
    // Config Servers
    GET_CONFIG_SERVERS = 'get_config_servers', GET_CONFIG_SERVERS_SUCCESS = 'get_config_servers_success', GET_CONFIG_SERVERS_FAILURE = 'get_config_servers_failure',
    SAVE_CONFIG_SERVERS = 'save_config_servers', SAVE_CONFIG_SERVERS_SUCCESS = 'save_config_servers_success', SAVE_CONFIG_SERVERS_FAILURE = 'save_config_servers_failure',
    // Bundling
    GET_CONFIG_BUNDLE_DATA = 'get_config_bundle_data', GET_CONFIG_BUNDLE_DATA_SUCCESS = 'get_config_bundle_data_success', GET_CONFIG_BUNDLE_DATA_FAILURE = 'get_config_bundle_data_failure',
    SAVE_CONFIG_BUNDLE_DATA = 'save_config_bundle_data', SAVE_CONFIG_BUNDLE_DATA_SUCCESS = 'save_config_bundle_data_success', SAVE_CONFIG_BUNDLE_DATA_FAILURE = 'save_config_bundle_data_failure',
    // Bundling Event
    GET_CONFIG_BUNDLE_EVENT_DATA = 'get_config_bundle_event_data', GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS = 'get_config_bundle_event_data_success', GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE = 'get_config_bundle_event_data_failure',
    SAVE_CONFIG_BUNDLE_EVENT_DATA = 'save_config_bundle_event_data', SAVE_CONFIG_BUNDLE_EVENT_DATA_SUCCESS = 'save_config_bundle_event_data_success', SAVE_CONFIG_BUNDLE_EVENT_DATA_FAILURE = 'save_config_bundle_event_data_failure',  
     // Document Mapping
    GET_DOCUMENT_MAPPING_DATA = 'get_document_mapping_data', GET_DOCUMENT_MAPPING_DATA_SUCCESS = 'get_document_mapping_data_success', GET_DOCUMENT_MAPPING_DATA_FAILURE = 'get_document_mapping_data_failure',
    SAVE_DOCUMENT_MAPPING_DATA = 'save_document_mapping_data', SAVE_DOCUMENT_MAPPING_DATA_SUCCESS = 'save_document_mapping_data_success', SAVE_DOCUMENT_MAPPING_DATA_FAILURE = 'save_document_mapping_data_failure',    
    // Pushback
    GET_CONFIG_PUSHBACK = 'get_config_pushback', GET_CONFIG_PUSHBACK_SUCCESS = 'get_config_pushback_success', GET_CONFIG_PUSHBACK_FAILURE = 'get_config_pushback_failure',
    SAVE_CONFIG_PUSHBACK = 'save_config_pushback', SAVE_CONFIG_PUSHBACK_SUCCESS = 'save_config_pushback_success', SAVE_CONFIG_PUSHBACK_FAILURE = 'save_config_pushback_failure',
    // XPath Field Mapping
    GET_CONFIG_XPATH_FIELD_MAPPING = 'get_config_xpath_field_mapping', GET_CONFIG_XPATH_FIELD_MAPPING_SUCCESS = 'get_config_xpath_field_mapping_success', GET_CONFIG_XPATH_FIELD_MAPPING_FAILURE = 'get_config_xpath_field_mapping_failure',
    // Request Package Types
    GET_REQUEST_PACKAGE_TYPES = 'get_request_package_types', GET_REQUEST_PACKAGE_TYPES_SUCCESS = 'get_request_package_types_success', GET_REQUEST_PACKAGE_TYPES_FAILURE = 'get_request_package_types_failure',
    // PUsh back Loans
    GET_PUSHBACK_LOANS = 'get_pushback_loans', GET_PUSHBACK_LOANS_SUCCESS = 'get_pushback_loans_success', GET_PUSHBACK_LOANS_FAILURE = 'get_pushback_loans_failure',    
    // Pushback Order Details
    GET_PUSHBACK_ORDER_DETAILS = 'get_pushback_order_details', GET_PUSHBACK_ORDER_DETAILS_SUCCESS = 'get_pushback_order_details_success', GET_PUSHBACK_ORDER_DETAILS_FAILURE = 'get_pushback_order_details_failure',    
    // Pushback Detail
    GET_PUSHBACK_DETAIL = 'get_pushback_detail', GET_PUSHBACK_DETAIL_SUCCESS = 'get_pushback_detail_success', GET_PUSHBACK_DETAIL_FAILURE = 'get_pushback_detail_failure',
    // Pushback Report
    GET_PUSHBACK_REPORT = 'get_pushback_report', GET_PUSHBACK_REPORT_SUCCESS = 'get_pushback_report_success', GET_PUSHBACK_REPORT_FAILURE = 'get_pushback_report_failure',  
    // Pushback Client Logs
    GET_PUSHBACK_CLIENT_LOGS = 'get_pushback_client_logs', GET_PUSHBACK_CLIENT_LOGS_SUCCESS = 'get_pushback_client_logs_success', GET_PUSHBACK_CLIENT_LOGS_FAILURE = 'get_pushback_client_logs_failure',    
}

// Configuration Area --> (Config Servers Screen)
interface getConfigServersAction { type: ActionType.GET_CONFIG_SERVERS; } // This is what is happening during the fetch of the data
interface getConfigServersFailureAction { type: ActionType.GET_CONFIG_SERVERS_FAILURE; payload: string; }
interface getConfigServersSuccessAction { type: ActionType.GET_CONFIG_SERVERS_SUCCESS; payload: IIntegration; }
interface saveConfigServersAction { type: ActionType.SAVE_CONFIG_SERVERS }
interface saveConfigServersFailureAction { type: ActionType.SAVE_CONFIG_SERVERS_FAILURE, payload: string; }
interface saveConfigServersSuccessAction { type: ActionType.SAVE_CONFIG_SERVERS_SUCCESS, payload: IIntegration; }
export type GetConfigActions = getConfigServersAction | getConfigServersSuccessAction | getConfigServersFailureAction;
export type SaveConfigActions = saveConfigServersAction | saveConfigServersSuccessAction | saveConfigServersFailureAction;

// Plug in Data
interface getConfigPluginDataAction { type: ActionType.GET_CONFIG_PLUGIN_DATA; } // This is what is happening during the fetch of the data
interface getConfigPluginDataFailureAction { type: ActionType.GET_CONFIG_PLUGIN_DATA_FAILURE; payload: string; }
interface getConfigPluginDataSuccessAction { type: ActionType.GET_CONFIG_PLUGIN_DATA_SUCCESS; payload: IPluginConfiguration; }
interface saveConfigPluginDataAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA }
interface saveConfigPluginDataFailureAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE, payload: string; }
interface saveConfigPluginDataSuccessAction { type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS, payload: IPluginConfiguration; }
export type GetPluginActions = getConfigPluginDataAction | getConfigPluginDataFailureAction | getConfigPluginDataSuccessAction;
export type SavePluginActions = saveConfigPluginDataAction | saveConfigPluginDataFailureAction | saveConfigPluginDataSuccessAction;

// Configuration - Document Bundles
interface getConfigBundlesAction { type: ActionType.GET_CONFIG_BUNDLE_DATA; }
interface getConfigBundlesFailureAction { type: ActionType.GET_CONFIG_BUNDLE_DATA_FAILURE; payload: string; }
interface getConfigBundlesSuccessAction { type: ActionType.GET_CONFIG_BUNDLE_DATA_SUCCESS; payload: IBundleConfiguration; }
interface saveConfigBundlesAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA; }
interface saveConfigBundlesFailureAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA_FAILURE; payload: string; }
interface saveConfigBundlesSuccessAction { type: ActionType.SAVE_CONFIG_BUNDLE_DATA_SUCCESS; payload: IBundleConfiguration; }
export type GetConfigBundleActions = getConfigBundlesAction | getConfigBundlesFailureAction | getConfigBundlesSuccessAction;
export type SaveConfigBundleActions = saveConfigBundlesAction | saveConfigBundlesFailureAction | saveConfigBundlesSuccessAction;

// Configuration - Document Event Bundles
interface getConfigEventBundlesAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA; }
interface getConfigEventBundlesSuccessAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_SUCCESS; payload: IEventBundleConfiguration; }
interface getConfigEventsBundlesFailureAction { type: ActionType.GET_CONFIG_BUNDLE_EVENT_DATA_FAILURE; payload: string; }
interface saveConfigEventBundlesAction { type: ActionType.SAVE_CONFIG_BUNDLE_EVENT_DATA; }
interface saveConfigEventBundlesSuccessAction { type: ActionType.SAVE_CONFIG_BUNDLE_EVENT_DATA_SUCCESS; payload: IEventBundleConfiguration; }   
interface saveConfigEventBundlesFailureAction { type: ActionType.SAVE_CONFIG_BUNDLE_EVENT_DATA_FAILURE; payload: string; }
export type GetConfigEventBundleActions = getConfigEventBundlesAction | getConfigEventBundlesSuccessAction | getConfigEventsBundlesFailureAction;
export type SaveConfigEventBundleActions = saveConfigEventBundlesAction | saveConfigEventBundlesSuccessAction | saveConfigEventBundlesFailureAction;

// Configuration - Document Event Bundles
interface getDocumentMappingAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA; }
interface getDocumentMappingSuccessAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA_SUCCESS; payload: IDocumentMappingConfiguration; }
interface getDocumentMappingFailureAction { type: ActionType.GET_DOCUMENT_MAPPING_DATA_FAILURE; payload: string; }
interface saveDocumentMappingAction { type: ActionType.SAVE_DOCUMENT_MAPPING_DATA; }
interface saveDocumentMappingSuccessAction { type: ActionType.SAVE_DOCUMENT_MAPPING_DATA_SUCCESS; payload: IDocumentMappingConfiguration; }
interface saveDocumentMappingFailureAction { type: ActionType.SAVE_DOCUMENT_MAPPING_DATA_FAILURE; payload: string; }
export type GetDocumentMappingActions = getDocumentMappingAction | getDocumentMappingSuccessAction | getDocumentMappingFailureAction;
export type SaveDocumentMappingActions = saveDocumentMappingAction | saveDocumentMappingSuccessAction | saveDocumentMappingFailureAction;

// Pushback
interface getConfigPushbackAction { type: ActionType.GET_CONFIG_PUSHBACK; }
interface getConfigPushbackFailureAction { type: ActionType.GET_CONFIG_PUSHBACK_FAILURE; payload: string; }
interface getConfigPushbackSuccessAction { type: ActionType.GET_CONFIG_PUSHBACK_SUCCESS; payload: IPushbackConfiguration; }
interface saveConfigPushbackAction { type: ActionType.SAVE_CONFIG_PUSHBACK; }
interface saveConfigPushbackSuccessAction { type: ActionType.SAVE_CONFIG_PUSHBACK_SUCCESS; payload: IPushbackConfiguration; }
interface saveConfigPushbackFailureAction { type: ActionType.SAVE_CONFIG_PUSHBACK_FAILURE; payload: string; }
export type GetConfigPushbackActions = getConfigPushbackAction | getConfigPushbackFailureAction | getConfigPushbackSuccessAction;
export type SaveConfigPushbackActions = saveConfigPushbackAction | saveConfigPushbackSuccessAction | saveConfigPushbackFailureAction;

// XPath Field Mapping
interface getConfigXPathFieldMappingAction { type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING; }
interface getConfigXPathFieldMappingSuccessAction { type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_SUCCESS; payload: IXpathFieldMap; }
interface getConfigXPathFieldMappingFailureAction { type: ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_FAILURE; payload: string; }
export type GetConfigXPathFieldMappingActions = getConfigXPathFieldMappingAction | getConfigXPathFieldMappingSuccessAction | getConfigXPathFieldMappingFailureAction;

// Request Package Types
interface getRequestPackageTypesAction { type: ActionType.GET_REQUEST_PACKAGE_TYPES; }
interface getRequestPackageTypesFailureAction { type: ActionType.GET_REQUEST_PACKAGE_TYPES_FAILURE; payload: string; }
interface getRequestPackageTypesSuccessAction { type: ActionType.GET_REQUEST_PACKAGE_TYPES_SUCCESS; payload: IPackageTypes; }
export type GetRequestPackageTypesActions = getRequestPackageTypesAction | getRequestPackageTypesFailureAction | getRequestPackageTypesSuccessAction;

// Pushback Dash Board 

// Pushback Loans
interface getPushbackLoansAction { type: ActionType.GET_PUSHBACK_LOANS; }
interface getPushbackLoansSuccessAction { type: ActionType.GET_PUSHBACK_LOANS_SUCCESS; payload: IPushbackLoans; }
interface getPushbackLoansFailureAction { type: ActionType.GET_PUSHBACK_LOANS_FAILURE; payload: string; }
export type GetPushbackLoansActions = getPushbackLoansAction | getPushbackLoansSuccessAction | getPushbackLoansFailureAction;

// Push back Order Details
interface getPushbackOrderDetailsAction { type: ActionType.GET_PUSHBACK_ORDER_DETAILS; }
interface getPushbackOrderDetailsSuccessAction { type: ActionType.GET_PUSHBACK_ORDER_DETAILS_SUCCESS; payload: IPushbackOrderDetails; }
interface getPushbackOrderDetailsFailureAction { type: ActionType.GET_PUSHBACK_ORDER_DETAILS_FAILURE; payload: string; }
export type GetPushbackOrderDetailsActions = getPushbackOrderDetailsAction | getPushbackOrderDetailsSuccessAction | getPushbackOrderDetailsFailureAction;

// Pushback Detail
interface getPushbackDetailAction { type: ActionType.GET_PUSHBACK_DETAIL; }
interface getPushbackDetailSuccessAction { type: ActionType.GET_PUSHBACK_DETAIL_SUCCESS; payload: IPushbackDetail; }
interface getPushbackDetailFailureAction { type: ActionType.GET_PUSHBACK_DETAIL_FAILURE; payload: string; }
export type GetPushbackDetailActions = getPushbackDetailAction | getPushbackDetailSuccessAction | getPushbackDetailFailureAction;

// Pushback Report
interface getPushbackReportAction { type: ActionType.GET_PUSHBACK_REPORT; }
interface getPushbackReportSuccessAction { type: ActionType.GET_PUSHBACK_REPORT_SUCCESS; payload: IPushbackReport; }
interface getPushbackReportFailureAction { type: ActionType.GET_PUSHBACK_REPORT_FAILURE; payload: string; }
export type GetPushbackReportActions = getPushbackReportAction | getPushbackReportSuccessAction | getPushbackReportFailureAction;

// Pushback Client Logs
interface getPushbackClientLogsAction { type: ActionType.GET_PUSHBACK_CLIENT_LOGS; }
interface getPushbackClientLogsSuccessAction { type: ActionType.GET_PUSHBACK_CLIENT_LOGS_SUCCESS; payload: IClientLogEntries; }
interface getPushbackClientLogsFailureAction { type: ActionType.GET_PUSHBACK_CLIENT_LOGS_FAILURE; payload: string; }
export type GetPushbackClientLogsActions = getPushbackClientLogsAction | getPushbackClientLogsSuccessAction | getPushbackClientLogsFailureAction;

export type Action = 
    GetConfigActions | SaveConfigActions |
    GetPluginActions | SavePluginActions |
    GetConfigBundleActions | SaveConfigBundleActions |
    GetConfigEventBundleActions | SaveConfigEventBundleActions |
    GetDocumentMappingActions | SaveDocumentMappingActions |
    GetConfigPushbackActions | SaveConfigPushbackActions |
    GetConfigXPathFieldMappingActions |
    GetRequestPackageTypesActions |
    GetPushbackLoansActions |
    GetPushbackOrderDetailsActions |
    GetPushbackDetailActions |
    GetPushbackReportActions |
    GetPushbackClientLogsActions;