export interface IDocumentBundling {
    PackageId: string;
    Signature: SignedOrUnsigned;
    BundlingOption: BundlingOption;
    OverrideConfig: boolean;
    MergeList: boolean;
    DocumentHandlingPackageConfigs: IDocumentHandlingPackageConfiguration[];
    MergerPackageConfigs: IMergerPackageConfiguration[];
    AllowMergerConfigDuplicate: boolean;
    AllowDocumentHandlingDuplicate: boolean;
}

export interface IDocumentHandlingPackageConfiguration {
    DocumentIndex: string;
    DocumentName: string;
    EncompassFolder: string;
    Suffix: string;
    ReuseFolder: boolean;
    EncompassAttachment: string;
}

export interface IMergerPackageConfiguration {
    MergeList: string[];
    MergeListConcatenated: string;
    EncompassFolder: string;
    ReuseFolder: boolean;
    EncompassAttachment: string;
    Suffix: string;
}

export enum SignedOrUnsigned {
    Signed = 1,
    Unsigned = 2,
    Other = 3
}

export enum BundlingOption {
    OneFolderPerPackage = 1,
    OneFolderPerDocument = 2
}

export interface IEventsBundling {
    EventName: string;
    DocumentPackageHandling: IDocumentBundling[];
}