import axios, { AxiosRequestConfig } from "axios";
import ApiService from "./apiService";
import EncompassService from "./encompassService";
import AuthService from "./authService";

class RequestService {

    private apiService: ApiService;
    constructor() {
        this.apiService = new ApiService(EncompassService.getRequestEndpoint() as string);
    }

    public async get<T>(token: string | null, route: string, configurationId: string): Promise<T> {

        try {
            
            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                }
            }

            let response;
            if (token === null) {
                const getRoute = `${EncompassService.getRequestEndpoint()}/v1/${route}?token=${encodeURIComponent((await new  AuthService().getToken()).access_token as string)}&configId=${encodeURIComponent(configurationId)}`;
                response = await axios.get(getRoute);
            } else
                response = await this.apiService.get(`/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Config:', error);
            throw error;
        }
    }
}

export default RequestService;