import { Dispatch } from "redux";
import { Action, ActionType } from "../../actions";
import PushbackService from "../../../services/pushbackService";
import { IPushbackReport } from "../../../models/configuration/pushback/IPushbackReport";

const getPushbackReportData = (configId: string, token: string | null, orderId: string, entryId: string): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>) => {

        dispatch({ type: ActionType.GET_PUSHBACK_REPORT });

        try {

            const payload = await new PushbackService().getPushbackReport<IPushbackReport>(token, configId, orderId, entryId);

            dispatch({
                type: ActionType.GET_PUSHBACK_REPORT_SUCCESS,
                payload: payload,
            });

        } catch (error: any) {

            console.error("Get Pushback Report ");
            if (error instanceof Error) {
                var payloadErrorMessage = `Failed to get push back report  data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_PUSHBACK_REPORT_FAILURE,
                    payload: payloadErrorMessage,
                });
            }

        }
    }
}

export default getPushbackReportData;