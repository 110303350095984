import { IConfiguration} from "../models/configuration/pushback/IConfiguration";

export const ReceivedDate_Population_Items = [
  "Consent",
  "Review",
  "SignAndSubmit",
];

export const DefaultPushbackData:IConfiguration=
{
  ReceivedDatePopulation:"",
  MaxConcurrency:"",
  MessageRetryVisibilityTimeoutSeconds:"",
  LoanLockedVisibilityTimeoutSeconds:"",
  MaxMessageRetryCount:"",
  UnnamedDocumentTitle:"",
  DisclosureTrackingMapping:null,
  IsDefault: false
}

export const GetPushbackFieldMappings = (defaultData: IConfiguration) => {
  var propertyList = Object.getOwnPropertyNames(defaultData);
  let pushbackFields: any = {};
  propertyList.forEach((key: string) => {
    return (pushbackFields[key] = GetFieldName(key));
  });
  return pushbackFields;
};

export const GetPushbackFieldNames = (defaultData: IConfiguration) => {
  var propertyList = Object.getOwnPropertyNames(defaultData);
  let pushbackFields: any = {};
  propertyList.forEach((key: string) => {
    return (pushbackFields[key] = key);
  });
  return pushbackFields;
};

export const GetFieldName = (key: string) => {
  switch (key) {
    case "ReceivedDatePopulation":
      return "Received Date Population";
    case "MaxConcurrency":
      return "Max Concurrency";
    case "MessageRetryVisibilityTimeoutSeconds":
      return "Minutes Before Pushback Resubmitted for Processing";
    case "LoanLockedVisibilityTimeoutSeconds":
      return "Minutes Before Locked Loan Pushback Resubmitted for Processing";
    case "UnnamedDocumentTitle":
      return "Unnamed Document";
    case "MaxMessageRetryCount":
      return "Number Of Attempts Before Auto-Abandon";
  }
};

export const Minutes_Min_Default_Value = 1;
export const Minutes_Max_Default_Value = 2000;
export const Concurrency_Min_Default_Value = 1;
export const Concurrency_Max_Default_Value = 2000;
export const Attempts_Min_Default_Value = 0;
export const Attempts_Max_Default_Value = 999;
export const Step_Default_Value = 1;

export const Get_Minutes_Pushback_DefaultValue = (configVal: any) => {
  const configMinutesVal = configVal / 60;
  if (configMinutesVal && !isNaN(configMinutesVal)) {
    if (configMinutesVal < Minutes_Min_Default_Value)
      return Minutes_Min_Default_Value;
    else if (configMinutesVal > Minutes_Max_Default_Value)
      return Minutes_Max_Default_Value;
    else return configMinutesVal;
  }
};

export const Get_Attempts_Pushback_DefaultValue = (configVal: any) => {
  if (
    (configVal && !isNaN(configVal)) ||
    configVal === Attempts_Min_Default_Value
  ) {
    if (configVal < Attempts_Min_Default_Value)
      return Attempts_Min_Default_Value;
    else if (configVal > Attempts_Max_Default_Value)
      return Attempts_Max_Default_Value;
    else return configVal;
  }
};

export const Get_Concurrency_Pushback_DefaultValue = (configVal: any) => {
  if (configVal && !isNaN(configVal)) {
    if (configVal < Concurrency_Min_Default_Value)
      return Concurrency_Min_Default_Value;
    else if (configVal > Concurrency_Max_Default_Value)
      return Concurrency_Max_Default_Value;
    else return configVal;
  }
};

export const Get_Seconds_Pushback_DefaultValue = (configVal: any) => {
  return configVal * 60;
};