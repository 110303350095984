import { Box, Button, Card, CardContent, Snackbar } from "@mui/material";
import React, { useState } from "react";
import ConfirmationDialog from "../../shared/ConfirmDialog";
import MuiAlert from '@mui/material/Alert';
import { AlertSeverity } from "../../../constants/AlertTypes";
import { useDialogData } from "../../../hooks/useDialogData";
import { useNavigate } from "react-router-dom";

interface ConfigBottomBarProps {
    save: () => void;
}

const ConfigBottomBar: React.FC<ConfigBottomBarProps> = ({ save }) => {
    const navigate = useNavigate();


    // Can set from state here, or use direct state values.
    // TODO: Implement Private Save and Private Delete    
    const [privateSaveDisabled, setPrivateSaveDisabled] = useState(true);
    const [privateDeleteDisabled, setPrivateDeleteDisabled] = useState(true);

    const dialogStates = useDialogData();

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    // Button functions
    const handlePushbackDashboard = () => {
        navigate('/pushback/dashboard');
    };
    const handleEvaluationResultReport = () => { openAlert('Evaluation Result Report Clicked', 'info'); };
    const handleRefreshMappingCache = () => { openAlert('Refresh Mapping Cache Clicked', 'info'); };
    const handlePrivateDelete = () => {
        if (privateDeleteDisabled) return;
    };
    const handlePrivateSave = () => {
        if (privateSaveDisabled) return;
    };

    const handleSave = () => {
        dialogStates.setDialogOpen(true);
        dialogStates.setDialogTitle('Save');
        dialogStates.setDialogMessage('Save Changes to Configuration?');
        dialogStates.setDialogSubMessage('Please refresh the screen after save.');
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "row",
                position: 'fixed',
                width: 'calc(83% - 32px)',
                bottom: 0,
                right: 16,
                left: 'calc(16.67%)',
                boxShadow: 'none',
            }}
        >
            <CardContent
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    padding: '8px !important',
                    alignItems: 'center'
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "flex-start", gap: 1, flexGrow: 1, alignItems: 'center' }}>
                    <Button
                        onClick={handlePushbackDashboard}
                        color={'secondary'}
                        variant='outlined'
                        size='small'
                    >
                        Pushback Dashboard
                    </Button>
                    <Button
                        onClick={handleEvaluationResultReport}
                        color={'secondary'}
                        variant='outlined'
                        size='small'
                    >
                        Evaluation Result Report
                    </Button>
                    <Button
                        onClick={handleRefreshMappingCache}
                        color={'secondary'}
                        variant='outlined'
                        size='small'
                    >
                        Refresh Mapping Cache
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 1,
                        flexGrow: 1,
                        alignItems: 'center'
                    }}
                >
                    <Button
                        disabled={privateDeleteDisabled}
                        onClick={handlePrivateDelete}
                        color={'warning'}
                        variant='contained'
                        size='small'
                    >
                        Private Delete
                    </Button>
                    <Button
                        disabled={privateSaveDisabled}
                        onClick={handlePrivateSave}
                        color={'warning'}
                        variant='contained'
                        size='small'
                    >
                        Private Save
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant='contained'
                        color={'primary'}
                        size='small'
                    >
                        Save
                    </Button>
                </Box>
                <ConfirmationDialog
                    open={dialogStates.dialogOpen}
                    title={dialogStates.dialogTitle}
                    message={dialogStates.dialogMessage}
                    subMessage={dialogStates.dialogSubMessage}
                    onConfirm={() => {
                        dialogStates.setDialogOpen(false);
                        save();
                    }}
                    onCancel={() => dialogStates.setDialogOpen(false)}
                />
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={alertOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackBarClose}
                >
                    <MuiAlert
                        onClose={handleSnackBarClose}
                        severity={alertSeverity}
                        sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                        {alertMessage}
                    </MuiAlert>
                </Snackbar>
            </CardContent>
        </Card>
    );
}

export default ConfigBottomBar;