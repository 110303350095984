import React, { useEffect, useState } from 'react';
import { Box, Button, List, Checkbox, TextField, CardContent, Card, ListItemButton, ListItemText, FormControlLabel, Typography, Snackbar} from '@mui/material';
import GenericDialog from '../GenericDialog';
import ExpressionEditor from './ExpressionEditor';
import { IExpression } from '../../../models/configuration/plugin/IConfiguration';
import { validateExpression, ExpressionValidationResult } from '../../../utils/expressionHelper';
import { AlertSeverity } from '../../../constants/AlertTypes';
import MuiAlert from '@mui/material/Alert';
import ConfirmationDialog from "../ConfirmDialog";
import { DisplayDialog } from "../DisplayDialog";

interface ExpressionProps {
    expressions: IExpression[] | undefined,
    updateParentExpressions: (expressions: IExpression[]) => void,
    title?: string;
}

const initialExpressionState: IExpression = {
    ExpressionName: '',
    ExpressionString: '',
    ExpressionMessage: '',
    Enabled: false
}

const ExpressionsControl: React.FC<ExpressionProps> = ({ expressions, updateParentExpressions, title }) => {

    // state
    const [open, setOpen] = useState(false);
    const [expressionList, setExpressionList] = useState<IExpression[]>([]);
    const [selectedExpression, setSelectedExpression] = useState<IExpression>(initialExpressionState);
    const [mode, setMode] = useState<string | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [titleSt] = useState<string>(title || "Any 'False' Expression will suppress/hide control");
    // Dialog 
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [displayDialogOpen, setDisplayDialogOpen] = useState(false);
    const [testTitle, setTestTitle] = useState<string>('');
    const [testResult, setTestResult] = useState<string>();
    //Alert Handling 
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');
   
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    useEffect(() => {
        if (expressions) {
            setExpressionList(expressions);
            setSelectedIndex(null);
        }
    }, [expressions]);

    useEffect(() => {

        setSelectedExpression(initialExpressionState);
        setSelectedIndex(null);

    }, [expressionList]);

    // Editor
    const handleOpen = (source: string) => {
        if (source === 'add') {
            setSelectedExpression(initialExpressionState);
            setSelectedIndex(null);
        }

        setMode(source);
        setOpen(true);
    }


    const handleClose = () => setOpen(false);

    const handleSelectExpression = (event: React.MouseEvent, index: number, expressionName: string) => {
        event.stopPropagation();
        setSelectedIndex(index);
        if (expressionList) {
            const expression = expressionList.find(expression => expression.ExpressionName === expressionName);
            if (expression) {
                setSelectedExpression(expression);
            }
        }
    }

    const handleValidateExpression = (expression: IExpression): ExpressionValidationResult | null => {

        if (expression && expression.ExpressionString?.length > 1) {
            return validateExpression(expression.ExpressionString);
        }

        return {
            isValid: false,
            errors: ['Expression string cannot be empty']
        };

    };

    const updateExpressions = (expression: IExpression, mode: string): string | null => {
        try {
            let updatedExpressionsList: IExpression[] = [...expressionList];

            switch (mode) {
                case 'add':
                    const existingExpression = updatedExpressionsList.find(exp => exp.ExpressionName === expression.ExpressionName);
                    if (!existingExpression) {
                        updatedExpressionsList = [...updatedExpressionsList, expression];
                    } else {
                        return "Expression already exists";
                    }
                    break;

                case 'edit':
                    const expressionIndex = updatedExpressionsList.findIndex(exp => exp.ExpressionName === expression.ExpressionName);
                    if (expressionIndex !== -1) {
                        updatedExpressionsList[expressionIndex] = expression;
                    } else {
                        return "Expression not found";
                    }
                    break;

                case 'delete':
                    updatedExpressionsList = updatedExpressionsList.filter(exp => exp.ExpressionName !== expression.ExpressionName);
                    break;

                default:
                    return "Unknown mode";
            }

            setExpressionList(updatedExpressionsList);
            updateParentExpressions(updatedExpressionsList);
            setOpen(false);

            return mode === 'add' ? "Added" : mode === 'edit' ? "Updated" : "Deleted";
        } catch (error) {
            console.error("Error updating expressions: ", error);
            return "Error occurred";
        }
    };

    function handleDelete(): void {
        if (selectedExpression) {
            updateExpressions(selectedExpression, 'delete');
        }
        setConfirmDialogOpen(false);
        openAlert('Item deleted successfully.', 'success');
    }

    const handleListClick = () => { // Click anywhere except on an expression to clear 
        setSelectedIndex(null);
        setSelectedExpression(initialExpressionState);
    }

    function handleTest(): void { 
        //TODO: Pending Expression validation
        setDisplayDialogOpen(true)
        setTestTitle("Result");
        setTestResult(`Testing Expression ${selectedExpression.ExpressionName}`);
    }

    function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const updatedExpression = { ...selectedExpression, Enabled: event.target.checked };
        setSelectedExpression(updatedExpression);
        updateExpressions(updatedExpression, 'edit');
    }

    return (
        <div>
        <Card sx={{ display: 'flex', margin: 2, justifyContent: 'center' }}>
            <CardContent sx={{ width: '100%' }}>
                {/* Outer Container */}
                <Typography sx={{ color: (theme) => theme.palette.warning.main, marginBottom: "1rem" }} >{titleSt}</Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>


                    {/* Left Side */}
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button variant="contained" onClick={() => handleOpen('add')}>Add</Button>
                            <Button variant="contained" disabled={selectedIndex === null} onClick={() => setConfirmDialogOpen(true)}>Delete </Button>
                            <Button variant="contained" disabled={selectedIndex === null} onClick={handleTest}>Test</Button>
                        </Box>

                        {/* List Box */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <List onClick={handleListClick} sx={{ border: '1px solid gray', flexGrow: 1, height: "19rem", overflowY: 'auto' }}>
                                {expressionList?.map((expression, index) => (
                                    <ListItemButton
                                        key={index}
                                        className='MuiListItemButton-root'
                                        sx={{
                                            color: 'blue',
                                            backgroundColor: selectedIndex === index ? 'lightgray' : 'transparent'
                                        }}
                                        onClick={(event) => handleSelectExpression(event, index, expression.ExpressionName)}
                                    >
                                        <ListItemText primary={expression.ExpressionName} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                    </Box>

                    {/* Right Side */}
                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {/* Checkbox and Edit Button */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedExpression.Enabled && selectedIndex !== null}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Enabled"
                            />

                            <Button variant="contained" disabled={selectedIndex === null} onClick={() => handleOpen('edit')}>Edit</Button>
                            <GenericDialog
                                open={open}
                                onClose={handleClose}
                                title="Expression Editor"
                            >
                                <ExpressionEditor
                                    expression={selectedExpression}
                                    onValidate={handleValidateExpression}
                                    onSave={updateExpressions}
                                    editorMode={mode}
                                />
                            </GenericDialog>
                        </Box>

                        {/* Text Boxes */}
                        <TextField multiline rows={5} fullWidth variant="outlined" value={selectedExpression.ExpressionString} />
                        <TextField multiline rows={5} fullWidth variant="outlined" value={selectedExpression.ExpressionMessage} />
                    </Box>
                </Box>
            </CardContent>
        </Card>

        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertOpen}
            autoHideDuration={4000}
            onClose={handleSnackBarClose} >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
        </Snackbar>

        <ConfirmationDialog 
            open={confirmDialogOpen} 
            title= {"Delete Item"}
            message={"Are you sure you want to delete the selected item?"}
            onConfirm={handleDelete} 
            onCancel={() => setConfirmDialogOpen(false)} />

        <DisplayDialog
            open={displayDialogOpen}
            title={testTitle}
            json={testResult}
            onClose={() => setDisplayDialogOpen(false)}
        />

    </div>
    );
};

export default ExpressionsControl;