import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { AlertSeverity } from '../../constants/AlertTypes';

interface InputDialogProps {
    open: boolean;
    mode: 'add' | 'edit' | 'delete' | 'view' | '';
    dataView?: string[] | [];
    confirmationMessage?: string;
    onSave: (mode: string, key: any, value: string) => void;
    onCancel: () => void;
    onDelete: () => void;
}

const InputDialogAdvanced: React.FC<InputDialogProps> =
    ({ open, mode, dataView, confirmationMessage, onSave, onCancel, onDelete }) => {

    // States
    const [keyState, setKeyState] = useState<any>(dataView?dataView[0]: '');
    const [valueSt, setValueState] = useState<string | undefined>(dataView?dataView[1]: '');

    // Alerts
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    useEffect(() => {
        if (mode === 'add') {
            clearValues();
        } else if (mode === 'edit' || mode === 'view') {
            setKeyState(dataView?dataView[0]: '');
            setValueState(dataView?dataView[1]: '');
        }
        
    }, [mode, dataView]);

    const handleSave = () => {

        switch (mode) {

            case 'add':
                if (!keyState || !valueSt) {
                    openAlert('Both text entries are required.', 'warning');
                    return;
                }
                onSave(mode, keyState, valueSt);
                clearValues();
                break; 

            case 'edit':
                if (!valueSt) {
                    openAlert('Please enter a value.', 'warning');
                    return;
                }
                onSave(mode, keyState, valueSt);
                clearValues();
                break;
            default:
                break
        }
    };

    // Alert Handling
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    const handleCancel = () => {
        clearValues();
        onCancel();
    }

    const clearValues = () => { 
        setValueState('');
        setKeyState('');
    }

    return (
        <Dialog open={open} onClose={onCancel} fullWidth >
            <DialogTitle>
                {mode === 'add' && 'Add Item'}
                {mode === 'edit' && 'Edit Item'}
                {mode === 'delete' && 'Delete Item'}
                {mode === 'view' && 'View Data'}
            </DialogTitle>
            <DialogContent>
                {mode === 'delete' ? (
                    <p>{confirmationMessage || 'Are you sure you want to delete this item?'}</p>
                ) : mode === 'view' ? (
                    dataView && dataView.length > 0 ? (
                        dataView?.map((val, index) => (
                            <TextField key={index} value={val} multiline rows={3} fullWidth margin="dense"
                                InputProps={{ readOnly: true }} variant="filled"
                            />
                        ))
                    ) : (
                        <p>No data to view</p>
                    )
                ) : mode === 'add' || mode === 'edit' ? (
                    <div>
                        <Typography variant="subtitle1">Response Message</Typography>
                        {mode === 'edit' ? (
                            <TextField value={keyState} multiline rows={3} fullWidth margin="dense"
                                InputProps={{ readOnly: true }} variant="filled"
                            />
                        ) : (
                            <TextField value={keyState} onChange={(e) => setKeyState(e.target.value)} fullWidth required />
                        )}
                        <Typography variant="subtitle1">Response Override</Typography>
                        <TextField value={valueSt} onChange={(e) => setValueState(e.target.value)} fullWidth required />
                    </div>
                ) : (
                    <TextField value={valueSt}
                        onChange={(e) => setValueState(e.target.value)}
                        fullWidth required
                    />
                )}
            </DialogContent>
            <DialogActions>
                {mode !== 'view' && (
                    <>
                        <Button onClick={() => handleCancel()} color="secondary">Cancel</Button>
                        {mode === 'delete' ? (
                            <Button onClick={onDelete} color="error">Delete</Button>
                        ) : (
                            <Button onClick={handleSave} color="primary">Save</Button>
                        )}
                    </>
                )}
                {mode === 'view' && (
                    <Button onClick={onCancel} color="primary">Close</Button>
                )}
            </DialogActions>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </Dialog>

    );
};

export default InputDialogAdvanced;