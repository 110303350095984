export interface IPushbackConfiguration {
  IsDefault: boolean;
  ReceivedDatePopulation: ReceivedDatePopulation;
  UnnamedDocumentTitle: string;
  MaxMessageRetryCount: number;
  MessageRetryVisibilityTimeoutSeconds: number;
  LoanLockedVisibilityTimeoutSeconds: number;
  MaxConcurrency: number;
  DisclosureTrackingMapping: IDisclosureTrackingMapping;
}

export interface IDisclosureTrackingMapping {
  IntentToProceed: {
      DocumentIds: number[];
      Ingestion: IntentToProceedIngestionOption;
  };
  AffiliatedBusinessDisclosureDocumentIds: number[];
  CHARMBookletDocumentIds: number[];
  SpecialInfoBookletDocumentIds: number[];
  HELOCBrochureDocumentIds: number[];
  AppraisalDocumentIds: number[];
  AVMDocumentIds: number[];
  HomeCounselingDisclosureDocumentIds: number[];
  HighCostDisclosureDocumentIds: number[];
  ProviderListDocumentIds: number[];
  SafeHarborDocumentIds: number[];
  LoanEstimateDocumentIds: number[];
  ClosingDisclosureDocumentIds: number[];
}

export enum IntentToProceedIngestionOption {  
  AllRecipients = 1,
  FirstBorrower = 2
}

export enum ReceivedDatePopulation {
  Consent = 1,
  Review = 2,
  SignAndSubmit = 3
}