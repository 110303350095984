import axios, { AxiosRequestConfig } from "axios";
import ApiService from "./apiService";
import EncompassService from "./encompassService";
import AuthService from "./authService";

class PushbackService {

    private apiService: ApiService;
    private PluginEndpoint = EncompassService.getPluginEndpoint();
    constructor() {
        this.apiService = new ApiService(this.PluginEndpoint as string);
    }

    /*
      * The Push back dashboard loans list --> Plugin Left side of the screen
      */
    public async getOrderDetailLoans<T>(token: string | null, configurationId: string, datetimeoffset: Date | null): Promise<T> {

        const route = "v1/order-detail-loans";
        try {
            if (!datetimeoffset)
                throw new Error("DateTimeOffset is required to fetch Order Detail Loans List");

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                    dateTime: datetimeoffset
                }
            }

            let response;
            if (token === null) {
                const urlRoute = `${this.PluginEndpoint}/${route}?token=${encodeURIComponent((await new AuthService().getToken()).access_token as string)}&configId=${encodeURIComponent(configurationId)}&dateTime=${encodeURIComponent(datetimeoffset.toISOString())}`;
                response = await axios.get(urlRoute);
            } else
                response = await this.apiService.get(`/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Order Detail Loans List:', error);
            throw error;
        }
    }

    /*
     * The Push back Order Details --> Right window of the screen
     */
    public async getOrderDetails<T>(token: string | null, configurationId: string, loanNumber: string): Promise<T> {

        const route = "v1/order-details";
        try {

            if (!loanNumber)
                throw new Error("Loan Number is required to fetch Order Details");

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                    loanNumber: loanNumber
                }
            }

            let response;
            if (token === null) {
                const urlRoute = `${this.PluginEndpoint}/${route}?token=${encodeURIComponent((await new AuthService().getToken()).access_token as string)}&configId=${encodeURIComponent(configurationId)}&loanNumber=${encodeURIComponent(loanNumber)}`;
                response = await axios.get(urlRoute);
            } else
                response = await this.apiService.get(`/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Order Details:', error);
            throw error;
        }
    }

    /*
     * The Push back Detail --> Middle window of the screen generate from the view link
     */
    public async getPushbackDetail<T>(token: string | null, configurationId: string, orderId: string): Promise<T> {

        const route = "v1/pushback-detail";
        try {
            if (!orderId)
                throw new Error("Order Id is required to fetch Push Back Detail");

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                    OrderId: orderId
                }
            }

            let response;
            if (token === null) {
                const urlRoute = `${this.PluginEndpoint}/${route}?token=${encodeURIComponent((await new AuthService().getToken()).access_token as string)}&configId=${encodeURIComponent(configurationId)}&orderId=${encodeURIComponent(orderId)}`;
                response = await axios.get(urlRoute);
            } else
                response = await this.apiService.get(`/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Push Back Detail:', error);
            throw error;
        }
    }

    /*
     * The Push back Report --> Popup Generated from the Middle Window View Link 
     */
    public async getPushbackReport<T>(token: string | null, configurationId: string, orderId: string, entryId: string): Promise<T> {

        const route = "v1/pushback-report";
        try {
            if (!orderId || !entryId)
                throw new Error("Order Id and Entry Id are required to fetch Push Back Report");

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                    OrderId: orderId,
                    EntryId: entryId
                }
            }

            let response;
            if (token === null) {
                const urlRoute = `${this.PluginEndpoint}/${route}?token=${encodeURIComponent((await new AuthService().getToken()).access_token as string)}&configId=${encodeURIComponent(configurationId)}&orderId=${encodeURIComponent(orderId)}&entryId=${encodeURIComponent(entryId)}`;
                response = await axios.get(urlRoute);
            } else
                response = await this.apiService.get(`/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Push Back Report:', error);
            throw error;
        }
    }

    /*
      * The Push back Logs --> Bottom window of the screen
      */
    public async getPushbackLogs<T>(token: string | null, configurationId: string, loanNumber: string): Promise<T> {

        const route = "v1/client-log";
        try {
            if (!loanNumber)
                throw new Error("Loan Number is required to fetch Pushback Client Logs");

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                    loanNumber: loanNumber
                }
            }

            let response;
            if (token === null) {
                const urlRoute = `${this.PluginEndpoint}/${route}?token=${encodeURIComponent((await new AuthService().getToken()).access_token as string)}&configId=${encodeURIComponent(configurationId)}&loanNumber=${encodeURIComponent(loanNumber)}`;
                response = await axios.get(urlRoute);
            } else
                response = await this.apiService.get(`/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Pushback Client Logs:', error);
            throw error;
        }
    }
}

export default PushbackService;