import React, { useState } from 'react';
import { Box, Button, Card, CardContent, List, ListItemButton, ListItemText } from '@mui/material';

interface LoansListProps {
    loans: string[] | null,
    selectedLoan: string,
    selectLoan: (loan: string) => void
}

const LoansList: React.FC<LoansListProps> =
    ({ loans, selectedLoan, selectLoan }) => {
        const [searchTerm, setSearchTerm] = useState('');
        const [sortAsc, setSortAsc] = useState(true);

        const filteredLoans = loans?.filter(loan =>
            loan.toLowerCase().includes(searchTerm.toLowerCase())
        );

        const sortedLoans = filteredLoans?.sort((a, b) => 
            sortAsc ? a.localeCompare(b) : b.localeCompare(a)
        );

        return (

            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Button onClick={() => setSortAsc(!sortAsc)}>
                            {sortAsc ? '↓' : '↑'}
                        </Button>
                    </div>
                    {sortedLoans && sortedLoans.length === 0 && <div>No Loans found</div>}
                    {sortedLoans && sortedLoans.length > 0 && (
                        <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                            <List>
                                {sortedLoans?.map((loan) => (
                                    <ListItemButton
                                        key={loan}
                                        sx={{ color: loan ? 'blue' : 'grey' }}
                                        selected={selectedLoan === loan}
                                        onClick={() => selectLoan(loan)}
                                    >
                                        <ListItemText primary={loan} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                    )}
                </CardContent>
            </Card>
        );
    }

export default LoansList;