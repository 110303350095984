import { Button, Typography } from "@mui/material"
import ElliHost from "../../services/elliHost"
import { useDispatch, useSelector } from "react-redux"
import { ITransactionRequest } from "../../models/ITransactionRequest"
import { RootState, store } from "../../state/store";
import { addRequestId, setLoading } from "../../state/appSlice"
import { API_REQUEST_STATUS, DELIVERY_PREFIX, EMAIL_REGEX, EPC_REQUEST_TYPES, REQUEST_TYPES } from "../../utils/constants";
import { IDeliveryType } from "../../models/configuration/plugin/IConfiguration";
import { useState } from "react";
import EmailInput from "./EmailInput";
import { IDocListItem } from "../../models/IDocListResponse";
import ErrorModal from "./ErrorModal";
import { IConformXErrors, IDocGenStatusModel } from "../../models/IDocGenStatusModel";
import DocGenApiService from "../../services/docGenApiService";
import GenericDialog from "../shared/GenericDialog";
import PreviewDocsService from "../../services/previewDocsService";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IRequestId } from "../../models/IRequestIds";

const OrderButton =
    (props: {
        requestType: string,
        packageType: string,
        parentTypeId: string,
        AlternateType: string,
        deliveryMethods: Array<IDeliveryType>
        disabled: boolean | undefined
        orderCountHandler: any
        alertHandler: any
        selectedDocuments: Array<IDocListItem>
        token: string,
        alwaysPreviewDocuments: boolean
    }) => {
        let epcTransactionType = EPC_REQUEST_TYPES.DISCLOSURES;
        const elliHost = ElliHost.getInstance();
        const dispatch = useDispatch();
        const [emails, setEmails] = useState<string>('')
        const [deliveryEmails, setDeliveryEmails] = useState<Array<string>>([]);
        const [collabEmails, setCollabEmails] = useState<Array<string>>([]);
        const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
        const [emailModalText, setEmailModalText] = useState<string>('');
        const [emailInputType, setEmailInputType] = useState<'email' | 'collab'>('email');
        const [emailInputError, setEmailInputError] = useState<boolean>(false);
        const [submitErrors, setSubmitErrors] = useState<IConformXErrors | null>(null);
        const [errorOpen, setErrorOpen] = useState<boolean>(false);
        const [successOpen, setSuccessOpen] = useState<boolean>(false);
        const [orderFailOpen, setOrderFailOpen] = useState<boolean>(false);
        const appState = useSelector((state: RootState) => state.appSlice)
        const requests = useTypedSelector<Array<IRequestId>>((state) => appState.requestIds);

        const buildTransactionObject = () => {
            const currentState = store.getState();

            const enablePreview = props.deliveryMethods.length < 1;
            let requestType = REQUEST_TYPES.DOCUMENT_REQUEST_IMPORT;
            if (props.requestType === REQUEST_TYPES.LIGHTS_OUT)
                requestType = REQUEST_TYPES.DOCUMENT_REQUEST;


            if (!currentState.appSlice.loanId) {
                props.alertHandler('No Loan ID', 'error');
                return;
            }
            if (!currentState.appSlice.configId) {
                props.alertHandler('No Config ID', 'error')
                return;
            }

            //frmDocutech.cs for these values
            let ronIndicator = false;
            let eNotaryIndicator = false;
            let eNoteIndicator = false;
            let eDiscloseIndicator = props.deliveryMethods?.some(d => d.Id?.toLowerCase().startsWith(DELIVERY_PREFIX.EDISCLOSE)) ?? false;
            let eSignIndicator = props.deliveryMethods?.some(d => d.Id?.toLowerCase().startsWith(DELIVERY_PREFIX.ESIGN)) ?? false;
            let eCloseIndicator = props.deliveryMethods?.some(d => d.Id?.toLowerCase().startsWith(DELIVERY_PREFIX.ECLOSE)) ?? false;
            let eNotaryType = "";
            let verificationVendorType = "";
            let formFreeLenderId = "";
            let iPenVendor = "";
            let iFrameVendor = "";

            const eCloseTypes = props.deliveryMethods?.filter(d => d.Id.toLowerCase().startsWith(DELIVERY_PREFIX.ECLOSE));
            if (eCloseTypes.length > 0) {
                if (eCloseTypes.some(d => d.Id.toLowerCase().includes(DELIVERY_PREFIX.RON)))
                    ronIndicator = true;
                if (eCloseTypes.some(d => d.Id.toLowerCase().includes(DELIVERY_PREFIX.ENOTE)))
                    eNoteIndicator = true;
                if (eCloseTypes.some(d => d.Id.toLowerCase().includes(DELIVERY_PREFIX.IPEN))) {
                    eNotaryIndicator = true;
                    const penVendor = props.deliveryMethods.find(d => d.CustomAttributes?.IPENVendor);
                    iPenVendor = penVendor?.CustomAttributes?.IPENVendor ?? "";
                    eNotaryType = iPenVendor;
                }
            }

            const esignTypes = props.deliveryMethods?.filter(d => d.Id.toLowerCase().startsWith(DELIVERY_PREFIX.ESIGN));
            if (esignTypes.length > 0) {
                eSignIndicator = true;
                verificationVendorType = props.deliveryMethods?.find(d => d.CustomAttributes.VerificationVendorType)?.CustomAttributes?.VerificationVendorType ?? "";
                formFreeLenderId = props.deliveryMethods?.find(d => d.CustomAttributes.FormFreeLenderID)?.CustomAttributes?.FormFreeLenderID ?? "";
            }

            if (props.packageType.toLowerCase().includes("closing")) {
                epcTransactionType = EPC_REQUEST_TYPES.CLOSING;
            }

            let request: ITransactionRequest = {
                type: epcTransactionType,
                options: {
                    RequestType: "DocGen",
                    CustomAttributes: {
                        CxIframeVendor: iFrameVendor ?? "",
                        CxIPenVendor: iPenVendor ?? "",
                        CxDeliveryType: props.deliveryMethods?.map(e => e.Id)?.join(','),
                        CxFormFreeLenderID: formFreeLenderId ?? "",
                        CxVerificationVendorType: verificationVendorType ?? ""
                    },
                    DocGenRequest: {
                        LoanId: currentState.appSlice.loanId ?? "",
                        ConfigurationId: currentState.appSlice.configId ?? "",
                        RequestOptions: {
                            RequestType: requestType,
                            PackageType: props.packageType,
                            AlternatePackageType: null,
                            //BundlingType:"Individual",
                            BundlingType: "Merged",
                            IncludeDocumentData: currentState.configPlugInData?.data?.Configuration?.DefaultValues?.IncludeDocumentData ?? false,
                            EnablePreview: enablePreview,
                            Username: currentState.appSlice.userFullName ?? "",
                            PushbackOptions: {
                                PushbackEndpointUrl: localStorage.getItem("pushbackUri") ?? process.env.ENCOMPASS_PUSHBACK_API_URI as string
                            },
                            ImpersonationOption: {
                                ClientCode: currentState.appSlice.clientCode ?? "",
                                EnableImpersonation: currentState.appSlice.clientCode ? true : false
                            },

                        },
                        DeliveryOptions: {
                            Fulfillment: {
                                SendToFulfillment: false,
                                ShippingMethod: ""
                            },
                        },
                    },
                },
                resources: currentState.appSlice.attachments
            }

            //alt/parent packageId
            if (props.AlternateType) {
                if (!isNaN(Number(props.AlternateType))) {
                    request.options.DocGenRequest!.RequestOptions.AlternatePackageType = props.AlternateType;
                    request.options.DocGenRequest!.RequestOptions.PackageType = props.parentTypeId;
                }
            }

            //For anything but LightsOn properties
            if (props.requestType !== REQUEST_TYPES.LIGHTS_ON) {
                request.options.DocGenRequest!.DeliveryOptions!.SolexEmail = null;
                request.options.DocGenRequest!.DeliveryOptions!.ClosingCollaboration = null;
                if (deliveryEmails != null && deliveryEmails.length > 0) {
                    request.options.DocGenRequest!.DeliveryOptions!.SolexEmail = {
                        Recipients: deliveryEmails
                    }
                }
                if (collabEmails != null && collabEmails.length > 0) {
                    request.options.DocGenRequest!.DeliveryOptions!.ClosingCollaboration = {
                        Recipients: collabEmails
                    }
                }

                request.options.DocGenRequest!.DeliveryOptions!.SolexEnotaryType = eNotaryType;
                request.options.DocGenRequest!.DeliveryOptions!.SolexVerificationVendorType = verificationVendorType;
                request.options.DocGenRequest!.DeliveryOptions!.SolexFormFreeLenderID = formFreeLenderId;
                request.options.DocGenRequest!.DeliveryOptions!.SolexEdisclose = eDiscloseIndicator;
                request.options.DocGenRequest!.DeliveryOptions!.SolexEsign = eSignIndicator;
                request.options.DocGenRequest!.DeliveryOptions!.SolexEclose = eCloseIndicator;
                request.options.DocGenRequest!.DeliveryOptions!.SolexEnote = eNoteIndicator;
                request.options.DocGenRequest!.DeliveryOptions!.SolexEnotary = eNotaryIndicator;
                request.options.DocGenRequest!.DeliveryOptions!.SolexRON = ronIndicator

                //if delivery type starts with 'mail'
                const mailDelivery = props.deliveryMethods.find(x => x.Id.toLowerCase().startsWith('mail'));
                if (mailDelivery) {
                    request.options.DocGenRequest!.DeliveryOptions!.Fulfillment.SendToFulfillment = true;
                    request.options.DocGenRequest!.DeliveryOptions!.Fulfillment.ShippingMethod = mailDelivery.Id.replace('Mail-', '');
                }

                if (props.selectedDocuments.length > 0) {
                    request.options.DocGenRequest!.RequestOptions.Documents = props.selectedDocuments.map(
                        (doc) => ({
                            DocumentIndex: doc.documentIndex ?? null,
                            PrimaryInstanceId: doc.primaryInstanceId ?? null,
                            SecondaryInstanceId: doc.secondaryInstanceId ?? null,
                            Copies: doc.copies ?? null
                        })
                    );
                }

                if (request.resources && request.resources.length > 0) {
                    request.options.DocGenRequest!.RequestOptions!.Attachments = request.resources.map(
                        (att) => ({
                            Name: att.name,
                            Type: att.name.split(".").pop() || null
                        })
                    );
                }
            }
            return request;
        }

        const submitOrder = async () => {
            //ask for emails 
            setEmailInputError(false);
            if (props.deliveryMethods.some(x => x.Id.toLowerCase() === 'email') && deliveryEmails.length < 1) {
                setEmailInputType('email')
                setEmailModalText("You have selected an Email delivery method type. This delivery requires emails. Please enter below, separating emails by a comma (,).");
                setOpenEmailModal(true);
                return;
            }
            if (props.deliveryMethods.some(x => x.Id.toLowerCase() === 'collaboration') && collabEmails.length < 1) {
                setEmailInputType('collab')
                setEmailModalText("You have selected a Collaboration delivery method type. This delivery requires emails. Please enter below, separating emails by a comma (,).");
                setOpenEmailModal(true);
                return;
            }

            //send to EPC via the JS library
            //JS lib sends to API via webhook
            dispatch(setLoading(true));
            const request = buildTransactionObject();
            console.log(request);
            if (request) {
                const transactionId = await elliHost.createTransaction(request)
                    .catch(error => {
                        console.log(error);
                    });

                //if we do get a transaction id returned, continue with submittal
                if (transactionId && transactionId.id) {
                    dispatch(addRequestId({
                        RequestId: transactionId.id,
                        Date: new Date()
                    }));

                    //go ahead and poll the status
                    const status = await pollSubmitStatus(transactionId.id);
                    if (status?.result?.conformXResponse?.errors) {
                        setSubmitErrors(status.result.conformXResponse.errors);
                        setErrorOpen(true);
                    }
                    else if (status?.result) {
                        setSuccessOpen(true);
                    }
                    else {
                        setOrderFailOpen(true);
                        console.log('Error fetching order status');
                    }
                }
            }
            dispatch(setLoading(false));
            props.orderCountHandler();
        }

        const pollSubmitStatus = async (transactionId: string) => {
            const waitInterval = 5000;
            const maxPoll = 60;
            let pollCount = 0;
            const apiService = new DocGenApiService();
            let keepPolling = true;
            while (keepPolling && pollCount < maxPoll) {
                const response: IDocGenStatusModel | void = await apiService.getDocGenStatus(transactionId, props.token)
                    .catch(e => console.log(e));
                if (response &&
                    (response.result.requestStatus === API_REQUEST_STATUS.COMPLETE || response.result.requestStatus === API_REQUEST_STATUS.ERROR)) {
                    return response;
                }
                pollCount++;
                await new Promise(resolve => setTimeout(resolve, waitInterval));
            }
        }

        const onEmailClose = () => {
            const emailArr = emails.split(',');
            if (!checkEmailsValid(emailArr)) {
                setEmailInputError(true);
                return;
            }
            if (emailInputType === 'email')
                setDeliveryEmails(emailArr);
            if (emailInputType === 'collab')
                setCollabEmails(emailArr)
            setOpenEmailModal(false);
            submitOrder();
        }

        const handleErrorDialogClose = () => {
            setSubmitErrors(null);
            setErrorOpen(false);
        }

        const handleSuccessDialogClose = () => {
            setSuccessOpen(false);
            if (props.alwaysPreviewDocuments) {
                const previewDocs = new PreviewDocsService();
                previewDocs.previewDocument(null, appState.accessToken ?? "", requests)
            }

        }
        const handleErrorClose = () => {
            setOrderFailOpen(false);
        }
        const checkEmailsValid = (emails: Array<string>) => {
            for (let i = 0; i < emails.length; i++) {
                let email = emails[i].trim();
                if (email.search(EMAIL_REGEX) === -1)
                    return false;
            }
            return true;
        }

        return (
            <>
                <Button variant="contained"
                    size="small"
                    color="success"
                    disabled={props.disabled}
                    onClick={() => submitOrder()}>Send</Button>

                <EmailInput title="Email Input"
                    text={emailModalText} open={openEmailModal}
                    error={emailInputError}
                    onClose={onEmailClose} submitButtonText="OK"
                    emails={emails} setEmails={setEmails} />

                <ErrorModal
                    title="Errors submitting"
                    errors={submitErrors}
                    modalOpen={errorOpen}
                    handleModalClose={handleErrorDialogClose}
                />
                <GenericDialog open={successOpen} title={"Successful submission"} onClose={handleSuccessDialogClose} cancelButtonText="Ok" >
                    <Typography>Your order has been submitted</Typography>
                </GenericDialog>
                <GenericDialog open={orderFailOpen} title={"Error"} onClose={handleErrorClose} >
                    <Typography>There was an error fetching your order</Typography>
                </GenericDialog>
            </>
        )
    }

export default OrderButton
