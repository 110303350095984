import { useCallback, useEffect } from "react";
import { useActions } from "../useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import { useTypedSelector } from "../useTypedSelector";

function usePushbackOrderDetails(loanNumber: string) {

    const { getPushbackOrderDetailsData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.pushbackOrderDetails);

    const isLoanNumberValid = loanNumber && loanNumber.trim() !== "";

    const fetchData = useCallback(() => {
        if (isLoanNumberValid) {
            const configId = EncompassService.getConfigId();
            if (configId) {
                getPushbackOrderDetailsData(configId, accessToken, loanNumber); // Access token not required since proxy will generate if needed locally 
            }
        }
    }, [getPushbackOrderDetailsData, accessToken, loanNumber, isLoanNumberValid]);

    useEffect(() => {
        if (loanNumber) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanNumber]);

    if (!isLoanNumberValid) {
        return { data: null, error: "", loading: false };
    }


    return { data, loading, error };
}

export { usePushbackOrderDetails };