import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IErrorMessageOverrides } from "../../../models/configuration/plugin/IConfiguration";
import { Box, Button, Card, CardContent, Divider, List, ListItemButton, ListItemText, Snackbar, Tooltip, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { base64DecodeMessages, base64Encode } from '../../../utils/convert';
import InputDialogAdvanced from '../../shared/InputDialogAdvanced';
import { AlertSeverity } from '../../../constants/AlertTypes';

export interface IRespMessagesProps {
    messages: IErrorMessageOverrides[];
    updateMessages: (updatedMessages: IErrorMessageOverrides[]) => void;
}

const RespMessages: React.FC<IRespMessagesProps> = ({ messages, updateMessages }) => {

    // State 
    const [messagesState, setMessagesState] = useState<IErrorMessageOverrides[]>([]);
    const [selectedMessage, setSelectedMessage] = useState<IErrorMessageOverrides | null>(null);
    const [messageKey, setMessageKey] = useState<string | null>(null);
    const [isSorted, setIsSorted] = useState<boolean>(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    // Dialog 
    const [messageDialogOpen, setMessageDialogOpen] = useState(false);
    const [messageValue, setMessageValue] = useState<string>('');
    const [messageDialogMode, setMessageDialogMode] = useState<'add' | 'edit' | 'delete' | 'view' | ''>('');
    const [dataView, setDataView] = useState<string[]>([]);

    // Refs
    const messagesUpdated = useRef<boolean>(false); // To Prevent multiple useEffect message Updates

    // Sync Scrolling 
    const leftContainerRef = useRef<HTMLDivElement>(null);
    const rightContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messages && messages.length > 0 && !messagesUpdated.current) {
            const decodedMessages = base64DecodeMessages(messages);
            setMessagesState(decodedMessages);
            messagesUpdated.current = true;
        }
    }, [messages]);

    const handleOpenDialog = (mode: 'add' | 'edit' | 'delete' | 'view') => {

        setMessageDialogMode(mode);
        if (mode === 'add') {
            setMessageDialogOpen(true);
        } else {
            if (selectedMessage) {
                setDataView([selectedMessage.ResponseMessage, selectedMessage.ResponseOverride]);
                setMessageDialogMode(mode);
                setMessageDialogOpen(true);
            }
        }
    }

    const handleSave = (mode: string, _key: string, value: string) => {
        if (!_key) { // Stop propagation if key is empty 
            return;
        }

        if (mode === 'add') {
            const newMessage: IErrorMessageOverrides = { ResponseMessage: _key, ResponseOverride: value };
            if (messagesState.find((message) => message.ResponseMessage === _key)) {
                openAlert("The Response Message already exists. Please enter a different one.", "error");
                return;
            }

            const updatedMessages = [...messagesState, newMessage];
            setMessagesState(updatedMessages);
            handleMessagesUpdate(updatedMessages);
            openAlert('Item added successfully.', 'success');
        } else if (mode === 'edit') {
            const newSelected = { ...selectedMessage, ResponseMessage: _key, ResponseOverride: value };
            setSelectedMessage(newSelected);
            setMessageKey(_key);
            const updatedMessages = messagesState.map((message) => {
                if (message.ResponseMessage === selectedMessage?.ResponseMessage) // If keys match, update the message
                    return newSelected;
                return message;
            });
            setMessagesState(updatedMessages);
            handleMessagesUpdate(updatedMessages);
            openAlert('Item updated successfully.', 'success');
        }

        setMessageDialogOpen(false);
    }

    // takes the updated messages and updates the parent state with base64 encoded messages
    const handleMessagesUpdate = (updatedMessages: IErrorMessageOverrides[]) => {

        const newMessages: IErrorMessageOverrides[] = updatedMessages.map((message: IErrorMessageOverrides) => {
            return {
                ResponseMessage: base64Encode(message.ResponseMessage),
                ResponseOverride: base64Encode(message.ResponseOverride)
            }
        })

        // update parent which will update state
        messagesUpdated.current = false;
        updateMessages(newMessages); // Back to the parent
    }

    const handleCancel = () => {
        setMessageDialogOpen(false);
    }

    const handleDelete = () => {
        const updatedMessages = messagesState.filter((message) => message.ResponseMessage !== selectedMessage?.ResponseMessage);
        setSelectedMessage(null);
        setMessageKey(null);
        handleMessagesUpdate(updatedMessages);
        setMessageDialogOpen(false);
        openAlert('Item deleted successfully.', 'success');
    }

    // Handling Sync Scrolling 
    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        if (leftContainerRef.current && rightContainerRef.current) {
            const scrollTop = e.currentTarget.scrollTop;
            leftContainerRef.current.scrollTop = scrollTop;
            rightContainerRef.current.scrollTop = scrollTop;
        }
    }

    const handleSelectedMessage = (message: IErrorMessageOverrides): void => {
        setSelectedMessage(message);
        setMessageKey(message.ResponseMessage);
        setMessageValue(message.ResponseOverride);
    }

    const handleSortResponseMessages = () => {
        const sortedMessages = [...messagesState].sort((a, b) => {
            if (isSorted) {
                return b.ResponseMessage.localeCompare(a.ResponseMessage);
            } else {
                return a.ResponseMessage.localeCompare(b.ResponseMessage); // Ascending
            }
        });
        setMessagesState(sortedMessages);
        setIsSorted(!isSorted);
    }

    const handleSortOverrideMessages = () => {
        const sortedMessages = [...messagesState].sort((a, b) => {
            if (isSorted) {
                return b.ResponseOverride.localeCompare(a.ResponseOverride);
            } else {
                return a.ResponseOverride.localeCompare(b.ResponseOverride); // Ascending
            }
        });
        setMessagesState(sortedMessages);
        setIsSorted(!isSorted);
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <div>
            <Card sx={{ display: 'flex', margin: 2, flexDirection: "column", justifyContent: 'flex-start', width: "80%" }}>
                <CardContent>

                    {/* Button Container */}
                    <Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", gap: 1 }}>
                        <Button variant="contained" disabled={!selectedMessage} onClick={() => handleOpenDialog('delete')}>Delete</Button>
                        <Button variant="contained" disabled={!selectedMessage} onClick={() => handleOpenDialog('edit')}>Edit</Button>
                        <Button variant="contained" disabled={!selectedMessage} onClick={() => handleOpenDialog('view')}>View</Button>
                        <Button variant="contained" onClick={() => handleOpenDialog('add')}>Add</Button>

                    </Box>
                    {/* Outer Container */}
                    {messagesState && messagesState.length === 0 && (

                        <Box sx={{ display: 'flex', flexDirection: "row", height: "35rem", gap: 2, marginTop: "3rem" }}>
                            <div>No messages to show</div>
                        </Box>

                    )}
                    {messagesState && messagesState.length > 0 && (
                        <Box sx={{ display: 'flex', flexDirection: "row", height: "35rem", gap: 2, marginTop: "3rem" }}>
                            {/* Left Container */}
                            <Box sx={{ width: "50%", display: 'flex', flexDirection: 'column', border: "1px solid black" }}>
                                <Typography
                                    onClick={handleSortResponseMessages}
                                    sx={{ ":hover": { cursor: "pointer" }, marginBottom: 2, position: "sticky", top: 0, backgroundColor: "white", zIndex: 1, padding: 1 }}
                                    variant="h6"
                                    align="center"
                                >
                                    Response Message
                                </Typography>
                                <List
                                    component="div"
                                    ref={leftContainerRef}
                                    onScroll={handleScroll}
                                    sx={{ overflowY: "auto", padding: 3, height: "100%" }}
                                >
                                    {messagesState.map((message) => (
                                        <React.Fragment key={message.ResponseMessage}>
                                            <ListItemButton
                                                sx={{
                                                    backgroundColor: selectedMessage?.ResponseMessage === message.ResponseMessage ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleSelectedMessage(message)}
                                            >
                                                <Tooltip title={<span style={{ fontSize: "1.5rem" }}>{message.ResponseMessage}</span>} arrow>
                                                    <ListItemText primary={message.ResponseMessage} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} />
                                                </Tooltip>
                                            </ListItemButton>
                                            <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }} />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>

                            {/* Right Container */}
                            <Box sx={{ width: "50%", display: 'flex', flexDirection: 'column', border: "1px solid black" }}>
                                <Typography
                                    onClick={handleSortOverrideMessages}
                                    sx={{ ":hover": { cursor: "pointer" }, marginBottom: 2, position: "sticky", top: 0, backgroundColor: "white", zIndex: 1, padding: 1 }}
                                    variant="h6"
                                    align="center"
                                >
                                    Response Override
                                </Typography>
                                <List
                                    component="div"
                                    ref={rightContainerRef}
                                    onScroll={handleScroll}
                                    sx={{ overflowY: "auto", padding: 3, height: "100%" }}
                                >
                                    {messagesState.map((message) => (
                                        <React.Fragment key={message.ResponseMessage}>
                                            <ListItemButton
                                                sx={{
                                                    backgroundColor: selectedMessage?.ResponseOverride === message.ResponseOverride ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleSelectedMessage(message)}
                                            >

                                                <Tooltip title={<span style={{ fontSize: "1.5rem" }}>{message.ResponseOverride}</span>} arrow>
                                                    <ListItemText primary={message.ResponseOverride} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} />
                                                </Tooltip>
                                            </ListItemButton>
                                            <Divider sx={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }} />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                    )}

                </CardContent>

            </Card>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={alertOpen}
                autoHideDuration={4000}
                onClose={handleSnackBarClose}
            >
                <MuiAlert
                    onClose={handleSnackBarClose}
                    severity={alertSeverity}
                    sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
            <InputDialogAdvanced
                open={messageDialogOpen}
                mode={messageDialogMode}
                // value={messageDialogMode === 'edit' ? messageValue : ''}
                // _key={messageDialogMode === 'edit' ? messageKey : ''}
                dataView={dataView}
                confirmationMessage='Are you sure you want to delete the selected item?'
                onSave={handleSave}
                onCancel={handleCancel}
                onDelete={handleDelete}
            />
        </div>
    );
};

export default RespMessages;