import React from "react";
import { TextField } from "@mui/material";

interface NumberInputProps {
  value: string | number | null | undefined;
  onChange?: (e: any) => void;
  min?: number;
  max?: number;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  max,
  min,
}) => {
  const handleNumberValidation = (e: any) => {
    var isKeyAllowed = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Insert",
      "Tab"
    ].includes(e.code)
      ? true
      : !isNaN(Number(e.key)) && e.code !== "Space";
    if (!isKeyAllowed) {
      e.preventDefault();
    }
  };
  return (
    <TextField
      fullWidth
      type="number"
      value={value}
      onKeyDown={(e: any) => {
        handleNumberValidation(e);
      }}
      onChange={onChange}
      InputProps={{
        inputProps: {
          min: min,
          max: max,
        },
      }}
    ></TextField>
  );
};

export default NumberInput;