import { useEffect, useCallback } from "react";
import { useActions } from "../useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import { useTypedSelector } from "../useTypedSelector";

function usePushbackLoans(loanStartDate: string) {

    const { getPushbackLoansData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.pushbackLoans);

    const fetchData = useCallback(() => {
        const configId = EncompassService.getConfigId();
        if (configId) {
            getPushbackLoansData(configId, accessToken, loanStartDate); // Access token not required since proxy will generate if needed locally
        }
    }, [getPushbackLoansData, accessToken, loanStartDate]);

    useEffect(() => {
        if (loanStartDate) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loanStartDate]);

    return { data, loading, error };
}
export { usePushbackLoans };