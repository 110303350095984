import axios, { AxiosRequestConfig } from "axios";
import ApiService from "./apiService";
import EncompassService from "./encompassService";
import AuthService from "./authService";

const useProxy = false; // Set to true to use proxy for CORS issues

/*
 * The Plugin service is used to get, post and put any of the calls to the 
 * plugin api which is used to handling a lot of the configuration area
 */
class PluginService {

    private apiService: ApiService;
    constructor() {
        this.apiService = new ApiService(EncompassService.getPluginEndpoint() as string);
    }

    public async getConfig<T>(token: string | null, route: string, configurationId: string): Promise<T> {

        try {
            
            if(!token && useProxy)  {
                const authService = new  AuthService();
                token = (await authService.getToken()).access_token;
            }

            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                params: {
                    configurationId: configurationId,
                }
            }

            let response;
            if (useProxy) 
                response = await axios.get(`${EncompassService.getPluginEndpoint()}/v1/configuration/${route}?token=${encodeURIComponent(token as string)}&configId=${encodeURIComponent(configurationId)}`);
            else
                response = await this.apiService.get(`/v1/configuration/${route}`, config);
            return response.data as T;
        } catch (error) {
            console.error('Error Fetching Config:', error);
            throw error;
        }
    }

    public async saveConfig<T>(token: string | null, route: string, configurationId: string, body: T): Promise<T> {

        try {
         
            if(!token && useProxy)  {
                const authService = new  AuthService();
                token = (await authService.getToken()).access_token;
            }
            
            if (!token || !configurationId || !body)
                throw new Error("Token, ConfigurationId and Body must be present to save configuration.");
            
            const config: AxiosRequestConfig = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }

            let response;
            if (useProxy) // Warning: Proxy may be serializing the data automatically 
                response = await axios.put(`${EncompassService.getPluginEndpoint()}/v1/configuration/${route}?token=${encodeURIComponent(token as string)}`, body);
            else {
                const requestBody = JSON.stringify(body); // handling serialization - CORS Network Error Issue
                response = await this.apiService.put(`/v1/configuration/${route}`, requestBody, config);
            }
            return response.data as T;
        } catch (error) {
            console.error('Error Saving Config:', error);
            throw error;
        }
    }
}

export default PluginService;