import React, { useEffect, useMemo, useRef, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { Box, SelectChangeEvent, Typography, Card, CardContent, Button, Snackbar, Select, MenuItem, Autocomplete, TextField } from "@mui/material";
import { useActions } from "../../hooks/useActions";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import PackagesList from "../../components/configuration/_packages/PackagesList";
import PackageDetails from "../../components/configuration/_packages/PackageDetails";
import PackagesTab from "../../components/configuration/_packages/PackagesTab";
import EncompassService from "../../services/encompassService";
import { IPackage } from "../../models/configuration/plugin/IConfiguration";
import { RootState, store } from "../../state/store";
import { useSelector } from "react-redux";
import ConfirmationDialog from "../../components/shared/ConfirmDialog";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import PackageGroupRename from "../../components/configuration/_packages/PackageGroupRename";
import { ConfigurationDataType, useConfigurationData } from "../../hooks/useConfigurationData";
import { ActionType } from "../../state/actions";
import { AlertSeverity } from "../../constants/AlertTypes";
import { usePackageTypes } from "../../hooks/usePackageTypes";
import { IPackageType } from "../../models/request/IRequest";
import { useNavigate } from "react-router-dom";
import { DisplayDialog } from "../../components/shared/DisplayDialog";

const ConfigPackages: React.FC = () => {

    const navigate = useNavigate();
    const { saveConfigPackagesData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const refreshPackageTypes = usePackageTypes();

    // Package State 
    const [packages, setPackages] = useState<IPackage[]>([]);
    const [packageList, setPackageList] = useState<IPackage[]>([]);
    const [packagesDropDown, setPackagesDropDown] = useState<string[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string | undefined>('');
    const [selectedPackage, setSelectedPackage] = useState<IPackage | null>(null);
    const [selectedPackageIdx, setSelectedPackageIdx] = useState<number | null>(null);
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');
    const [initialSelectionMade, setInitialSelectionMade] = useState(false);

    // Refresh States
    const [packageTypes, setPackageTypes] = useState<IPackageType[] | null>(refreshPackageTypes?.PackageTypes || null);

    // Sub States
    const [tabIndex] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('Are you sure you want to delete this package?');
    const [selectedGroupToReassign, setSelectedGroupToReassign] = useState<string | undefined>('');
    const [openReassignGroup, setOpenReassignGroup] = useState<boolean>(false);
    const [openPackageId, setOpenPackageId] = useState<boolean>(false);
    const [selectedNewPackageId, setSelectedNewPackageId] = useState<string | undefined>('');
    const [openPackageGroupRename, setOpenPackageGroupRename] = useState<boolean>(false);
    const [openRefreshConfirm, setOpenRefreshConfirm] = useState(false);
    const [openRefreshCompletionConfirm, setOpenRefreshCompletionConfirm] = useState(false);
    const [openDisplayDialog, setOpenDisplayDialog] = useState(false);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');
    const [saving, setSaving] = useState<boolean>(false);

    // Loading Data
    const hasFetchedData = useRef(false);
    const { data, error, loading } = useConfigurationData(ConfigurationDataType.PACKAGES);
    const mData = useMemo(() => data, [data]);

    useEffect(() => {
        if (!hasFetchedData.current && data) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
            setPackages(mData);
            hasFetchedData.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Handling Saving Error 
    useEffect(() => {
        if (!saveError && data !== null) { setPackages(mData);  } 
        if (error) { setErrorMessage(error); }
    }, [data, saveError, mData, error]);

    // Packages changed
    useEffect(() => {
        if (packages && packages.length > 0) {
            updatePackagesDropDown(packages);
            saveState(); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packages]);

    // Handling Initial Selection Mode 
    useEffect(() => {
        if (packagesDropDown.length > 0 && !initialSelectionMade) {
            setSelectedGroup(packagesDropDown[0]);
            setInitialSelectionMade(true);
        } 
    }, [packagesDropDown, initialSelectionMade]);

    const handleGroupChange = (event: SelectChangeEvent<string>) => { setSelectedGroup(event.target.value as string); }

    const updatePackagesDropDown = (packages: IPackage[]) => {
        const uniqueGroups = Array.from(new Set(packages.map((p) => p.Group)));
        setPackagesDropDown(uniqueGroups);
    }

    // Group Changed
    useEffect(() => {
        if (selectedGroup && packages) {
            const updatedPackageList = packages.filter((p) => p.Group === selectedGroup);
            setPackageList(updatedPackageList);
        }
    }, [selectedGroup, packages]);

    // New Package Selected 
    const selectPackage = (index: number, id: string) => {
        setSelectedPackageIdx(index);
        setSelectedPackage(packageList.find(p => p.Id === id) || null);
    }

    // Package Updated 
    useEffect(() => {
        if (selectedPackage != null && packages != null && packages.length > 0) {
            const existingPackage = packages.find((p) => p.Id === selectedPackage.Id);
            if (existingPackage && existingPackage !== selectedPackage) {
                const updatedPackageList = packages.map((p) => p.Id === selectedPackage.Id ? selectedPackage : p);
                refreshLists(updatedPackageList, selectedGroup);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPackage]);

    // Package Property Changes
    const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Enabled", event.target.checked); };
    const handleOrderChange = (event: SelectChangeEvent) => { handleControlPropChange("Order", event.target.value); };
    const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Caption", event.target.value); };
    const handleToolTipChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("ToolTipText", event.target.value); };
    const handleAutoSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("AutoSelectWhen", event.target.value); }
    const handleControlPropChange = (property: keyof IPackage, value: any) => {
        if (selectedPackage) {
            const updatePackage = { ...selectedPackage, [property]: value };
            setSelectedPackage(updatePackage);
        }
    }

    const updateSelectedPackage = (updatedControl: IPackage) => { setSelectedPackage(updatedControl); }
    const onUpdateUsers = (users: string[]) => {
        setSelectedPackage(
            selectedPackage ? { ...selectedPackage, Users: [...users] } : null,
        )
    }

    // *** DELETE HANDLING ***
    const DeletePackage = () => {
        setConfirmDeleteMessage(`Are you sure you want to delete package: '${selectedPackage?.Id}' ?`);
        setOpenDeleteConfirm(true);
    }

    const handleConfirmDelete = () => {
        setOpenDeleteConfirm(false);
        if (selectedPackage) {
            const updatedPackages = packages.filter((p) => p.Id !== selectedPackage.Id);
            setPackages(updatedPackages);
            setPackageList(updatedPackages.filter((p) => p.Group === selectedGroup));
            setSelectedPackage(null);
        }
    }

    const handleConfirmDeleteCancel = () => { setOpenDeleteConfirm(false); }

    // *** GROUP RE-ASSIGNMENT HANDLING ***
    const handleGroupReassignment = (event: React.SyntheticEvent, newValue: string | null) => { setSelectedGroupToReassign(newValue || ""); }
    const handleGroupReassignmentInputChange = (event: React.SyntheticEvent, newInputValue: string) => { setSelectedGroupToReassign(newInputValue); }

    const ReAssignGroupSubmit = () => {
        if (selectedPackage && selectedGroupToReassign) {
            const updatedPackage = { ...selectedPackage, Group: selectedGroupToReassign };
            const updatedPackages = packages.map((p) => p.Id === selectedPackage.Id ? updatedPackage : p);
            refreshLists(updatedPackages, selectedGroupToReassign);
            setSelectedPackage(updatedPackage);
        }
        setOpenReassignGroup(false);
        openAlert(`Successfully Re-Assigned to Group: ${selectedGroupToReassign}`, 'success');
    }

    // *** CHANGE PACKAGE ID HANDLING ***
    const updatePackageId = () => { setOpenPackageId(!openPackageId); }
    const handleNewPackageIdAssignment = (newPackageId: string) => { setSelectedNewPackageId(newPackageId); }
    const UpdatePackageIdSubmit = () => {
        if (selectedPackage && selectedNewPackageId) {
            const updatedPackage = { ...selectedPackage, Id: selectedNewPackageId };
            const updatedPackages = packages.map((p) => p.Id === selectedPackage.Id ? updatedPackage : p);
            refreshLists(updatedPackages, selectedGroup);
            setSelectedPackage(updatedPackage);
            setOpenPackageId(false);
            openAlert(`Successfully Updated Package ID: ${selectedNewPackageId}`, 'success');
        } else {
            openAlert("No Package Selected or New Package ID is empty", 'error');
        }
    }

    // Update Package List
    const refreshLists = (updatePackages: IPackage[], group: string | undefined) => {
        setPackages(updatePackages);
        // Update package list for only the selected group
        setPackageList(updatePackages.filter((p) => p.Group === group));
    }

    // Call this to save local session redux state
    const saveState = () => {
        try {
            if (packages.length === 0) return;
            const currentState = store.getState();
            const configPlugInData = currentState.configPlugInData.data;
            const updatedConfigPlugInData = {
                ...configPlugInData,
                Configuration: {
                    ...configPlugInData?.Configuration,
                    Packages: packages,
                },
            };
            store.dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
                payload: updatedConfigPlugInData
            });

        } catch (e) {
            console.log("Error Saving State: ", e);
            openAlert(`Error Saving State: ${e}`, 'error');
        }
    }

    const save = async () => {
        try {
            setSaving(true);
            const configId = selectedConfigId || EncompassService.getConfigId() as string;
            await saveConfigPackagesData(configId, [...packages], accessToken);
            openAlert("Successfully Saved Changes", 'success');
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            const error = `${e}`;
            console.log("Save Error: ", error);
            setSaveError(true);
            setErrorMessage(error);
        } finally {
            setSaving(false);
        }
    }

    // *** RENAME GROUP HANDLING  ***
    const submitPackageGroupRename = (newGroupName: string) => {
        if (selectedGroup === newGroupName) {
            openAlert(`New name '${newGroupName}' is the same as the existing name, please try a different name`, 'info');
            return;
        }

        if (selectedGroup && newGroupName) {
            const updatedPackages = packages.map((pkg) => {
                return pkg.Group === selectedGroup ? { ...pkg, Group: newGroupName } : pkg
            }).filter(pkg => pkg.Group !== selectedGroup);
            refreshLists(updatedPackages, newGroupName);
            setSelectedGroup(newGroupName);
            openAlert(`Successfully renamed group to: '${newGroupName}'`, 'success');
        }
        setOpenPackageGroupRename(false);
    }

    // Alert Handling 
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    // *** REFRESH HANDLING ***

    useEffect(() => {
        if (refreshPackageTypes) {
            setPackageTypes(refreshPackageTypes?.PackageTypes || null);
        }
    }, [refreshPackageTypes]);

    const handleConfirmRefresh = () => {
        setOpenRefreshConfirm(false);
        if (packageTypes) {
            executeRefreshPackages();
        }
    }

    const executeRefreshPackages = async () => {
        // Execute refresh logic 
        const newPackageArray = refreshPackagesWithPackageTypes();

        // update the packages with the new array data
        updatePackagesWithNewPackagesArray(newPackageArray);

        // save the new packages to the api
        await save();

        // Open Dialog 
        setOpenRefreshCompletionConfirm(true);
    }

    /*
        Take each request package type and iterate through them looking at each package type and alternate types and find the packages that matches
        them from the packageList which is the main packages list in the page. 
        If a match is found then update the package from the package list with delivery types from the packages deliveryMethods array
     */
    const refreshPackagesWithPackageTypes = (): IPackage[] => {
        // Iterate through the packageTypes 
        const newPackageArray: IPackage[] = [];
        packageTypes?.forEach((packageType, index) => {
            const pType = packageType.Type;
            const pAlternateTypeArr = packageType.AlternatePackageTypes;
            // only process if there is a type and alternate types
            if (pType && pAlternateTypeArr.length > 0) {
                // look for package in packages that has the same type , any value from it's AlternatePackageId
                const packagesToUpdate = packages.filter((pkg) =>
                    pkg.Id === pType && pAlternateTypeArr.some((alt) => alt.Name === pkg.AlternatePackageId)
                );
                // if there are packages to update then update the ValidDeliveryTypes of the packages 
                if (packagesToUpdate.length > 0) {
                    packagesToUpdate.forEach((pkg) => {
                        const deliveryMethods = packageType.DeliveryMethods.map((dm) => dm.Type);
                        newPackageArray.push({ ...pkg, ValidDeliveryTypes: deliveryMethods });
                    });
                }
            }
        });
        return newPackageArray;
    }

    const updatePackagesWithNewPackagesArray = (newPackageArray: IPackage[]) => {
        if (newPackageArray.length === 0) return;
        // Update the packages with the new packages array 
        const updatedPackages = packages.map((pkg) => {
            const updatedPkg = newPackageArray.find((newPkg) => newPkg.Id === pkg.Id);
            return updatedPkg || pkg;
        });
        // Refresh Page list with the updated packages
        refreshLists(updatedPackages, selectedGroup);
    }

    return (
        <>
            <section>
                <div>
                    {(loading || saving) && <ConfigLoad loading={loading || saving} />}
                </div>
                <div>

                    <Box
                        mt={1} component="main" sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1, overflow: "auto", minHeight: '100vh', margin: "2px"
                        }}
                        aria-hidden={loading || saving ? "true" : undefined} // Replace aria-hidden with inert
                    >

                        <ConfigTopBar save={save} error={errorMessage || null} />

                        <div className="configPackageContainer">
                            {/* Left Side */}
                            <div className="configPackageSubContainer-Left">
                                <Box sx={{ margin: 5 }}>
                                    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2,
                                                alignItems: 'center', marginBottom: 2

                                            }}>
                                                <Button onClick={() => setOpenRefreshConfirm(true)} variant="contained" size="small" >Refresh</Button>
                                                <Button disabled={!selectedGroup} onClick={() => setOpenPackageGroupRename(true)} variant="contained" size="small" >Rename</Button>
                                                <PackageGroupRename
                                                    open={openPackageGroupRename}
                                                    onClose={() => setOpenPackageGroupRename(false)}
                                                    onSubmit={submitPackageGroupRename}
                                                    existingName={selectedGroup || ""}
                                                />

                                            </Box>

                                            <Typography fontSize={14}>Select a group</Typography>
                                           
                                            <Select
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={selectedGroup}
                                                onChange={handleGroupChange}
                                                label="Select Group"
                                            >
                                                {packagesDropDown.map((group, index) => (
                                                    <MenuItem key={index} value={group}>
                                                        {group}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </CardContent>
                                    </Card>
                                </Box>
                                <Box>
                                    <PackagesList
                                        packages={packageList}
                                        selectedPackageIdx={selectedPackageIdx}
                                        selectPackage={selectPackage}
                                    />

                                    {selectedPackage && (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, margin: 5 }}>

                                            {/* ReAssign Group */}
                                            {openReassignGroup && (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: "1px dashed grey", padding: "10px" }}>
                                                    <Typography fontSize={14}>Package Re-Assign to a Group</Typography>
                                                    <Autocomplete value={selectedGroupToReassign} onChange={handleGroupReassignment} onInputChange={handleGroupReassignmentInputChange} options={packagesDropDown}
                                                        renderInput={(params) => (<TextField {...params} label="Select Group to Re-Assign" variant="outlined" size="small" fullWidth />)}
                                                        freeSolo // Free Solo allows free typing 
                                                    />
                                                    <Button onClick={() => ReAssignGroupSubmit()} variant="contained" size="small" disabled={!selectedGroupToReassign} >Submit</Button>
                                                </Box>
                                            )}

                                            <Button onClick={() => setOpenReassignGroup(!openReassignGroup)} variant="contained" size="small" >Re-Assign Group</Button>

                                            {/* Update Package ID */}
                                            {openPackageId && (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: "1px dashed grey", padding: "10px" }}>
                                                    <Typography fontSize={14}>Package Re-Assign to a Group</Typography>
                                                    <Typography variant="subtitle1" sx={{ margin: "5px" }}>From: {selectedPackage.Id}</Typography>
                                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start" }}>
                                                        <Typography variant="subtitle1" sx={{ margin: "5px" }}>To: </Typography>
                                                        <TextField value={selectedNewPackageId} onChange={(e) => handleNewPackageIdAssignment(e.target.value)} sx={{ flex: 1 }} fullWidth variant="outlined" size="small" />
                                                    </div>
                                                    <Button onClick={() => UpdatePackageIdSubmit()} variant="contained" size="small" disabled={!selectedNewPackageId} >Submit</Button>
                                                </Box>
                                            )}

                                            <Button onClick={() => updatePackageId()} variant="contained" size="small" >Update Package ID</Button>
                                            <Button onClick={() => DeletePackage()} variant="contained" size="small" color="error" >Delete</Button>
                                        </Box>
                                    )}
                                </Box>

                            </div>
                            <div className="configPackageSubContainerBottom">
                                {/* Right Side */}
                                <div className="configPackageSubContainer-Right"
                                    style={{ pointerEvents: selectedPackage ? 'auto' : 'none', opacity: selectedPackage ? 1 : 0.5 }}>
                                    <Box sx={{ margin: 5 }}>
                                        <PackageDetails
                                            selectedPackage={selectedPackage}
                                            handleOnEnabledChange={handleEnabledChange}
                                            handleOnOrderChange={handleOrderChange}
                                            handleOnCaptionChange={handleCaptionChange}
                                            handleOnToolTipTextChange={handleToolTipChange}
                                            handleOnAutoSelectChange={handleAutoSelectChange}
                                        />
                                    </Box>
                                    <Box>
                                        <PackagesTab
                                            tabIndex={tabIndex}
                                            selectedPackage={selectedPackage}
                                            updateParentPackage={updateSelectedPackage}
                                            sendUserUpdate={onUpdateUsers}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={alertOpen}
                            autoHideDuration={4000}
                            onClose={() => setAlertOpen(false)}
                        >
                            <MuiAlert
                                onClose={() => setAlertOpen(false)}
                                severity={alertSeverity}
                                sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                                {alertMessage}
                            </MuiAlert>
                        </Snackbar>
                        <ConfirmationDialog 
                            open={openDeleteConfirm} 
                            title={"Delete Package"} 
                            message={confirmDeleteMessage} 
                            onConfirm={handleConfirmDelete} 
                            onCancel={handleConfirmDeleteCancel} />
                        <ConfirmationDialog
                            open={openRefreshConfirm}
                            title="Yes or No"
                            message="This will update packages and delivery constraints and will result in this configuration form closing. Do you wish to continue?"
                            onConfirm={handleConfirmRefresh}
                            onCancel={() => setOpenRefreshConfirm(false)}
                            cancelName="No"
                            confirmName="Yes"
                        />
                        <ConfirmationDialog
                            open={openRefreshCompletionConfirm}
                            title="Complete"
                            message="Packages and Delivery Refresh Complete. Do you want to view the response?"
                            onConfirm={() => setOpenDisplayDialog(true)}
                            onCancel={() => {
                                setOpenRefreshCompletionConfirm(false); 
                                navigate('/order'); 
                            }}
                            cancelName="No"
                            confirmName="Yes"
                        />
                        <DisplayDialog
                            open={openDisplayDialog}
                            onClose={() => {
                                setOpenDisplayDialog(false); navigate('/order');
                            }}
                            title="Packages"
                            json={{refreshPackageTypes}}
                        />
                    </Box>
                </div>
                <ConfigBottomBar save={save} />
            </section>
        </>
    )
}

export default ConfigPackages;